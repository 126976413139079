import React, { Component, useState } from "react";
import { connect } from "react-redux";
import {
  doAddQuestion,
  doUpdateQuestion,
} from "../../../state/actions/question";
import TextEditor from "../docs/Editor/TextEditor";

export default function QuestionEditor({
  newContent,
  question = {},
  submit,
  cancel,
}) {
  const [content, setContent] = useState(question ? question.content : "");

  const onChangeContent = (content) => {
    setContent(content);
  };

  const submitQuestion = () => {
    submit({ ...question, content });
  };

  return (
    <div>
      <TextEditor
        content={content}
        setContent={onChangeContent}
        className="minimal"
        placeholder="Type a question ..."
        minimal
      />
      <div className="dfjbac m-t-md">
        <button
          style={{ width: 100, height: 39 }}
          className="btn btn-default btn-sm mb-0 mr-1"
          onClick={(e) => {
            e.preventDefault();
            cancel();
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-dark mb-0"
          onClick={submitQuestion}
        >
          {newContent ? "Add" : "Update"}
        </button>
      </div>
    </div>
  );
}
