import React from "react";

const RichTextView = ({ content, onClick }) => (
  <div className="tiptap">
    <div
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      dangerouslySetInnerHTML={{ __html: content }}
      className="ProseMirror"
    />
  </div>
);

export default RichTextView;
