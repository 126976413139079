import React, { Component } from "react";
import { clearStored } from "../../services/DataService";
import UserMenu from "./UserMenu";
import { Link, redirect } from "react-router-dom";

class TopNav extends Component {
  logOut = () => {
    this.props.history.push("/signin");
    clearStored();
  };

  openUserProfile = () => {
    this.props.history.push("/profile");
  };

  hideClose = () => {
    return this.props.location && this.props.location.pathname === "/groups";
  };

  render() {
    return (
      <div className="row border-bottom">
        <nav
          className="navbar navbar-static-top"
          role="navigation"
          style={{
            margin: 0,
            padding: "10px 15px 10px 15px",
          }}
        >
          <Link
            style={{ cursor: "pointer", padding: "0 20px" }}
            to={"/home"}
            className="navbar-header"
          >
            {/*Contxt*/}
            {/*font Outfit*/}
            <img src="/img/logo/logo1.png" style={{ height: 34 }} />
            {/*<div style={{ height: 34 }} />*/}
          </Link>

          {/*<SearchBar />*/}

          <div style={{ marginRight: 20 }}>
            <UserMenu
              openUserProfile={this.openUserProfile}
              logOut={this.logOut}
            />
          </div>
        </nav>
      </div>
    );
  }
}

export default TopNav;
