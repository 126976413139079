import {
  ADD_CHAT_GROUP,
  ADD_CHAT_MESSAGE,
  EDIT_CHAT_MESSAGE,
  LOAD_CHAT_GROUPS,
  LOAD_CHAT_MESSAGES,
  REMOVE_CHAT_GROUP,
  REMOVE_CHAT_MESSAGE,
  RESET_CHAT,
  SET_CHAT_GROUP,
} from "../actionTypes";

const initialState = {
  groups: [],
  selectedGroup: null,
  messages: {},
  messagesLoaded: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_CHAT: {
      return initialState;
    }
    case SET_CHAT_GROUP: {
      const { group } = action.payload;
      return {
        ...state,
        selectedGroup: group,
      };
    }

    case LOAD_CHAT_MESSAGES: {
      const { items = [], replace, groupId } = action.payload;
      let { messages, messagesLoaded } = state;
      let existingItems = messages[groupId] || [];
      if (replace) {
        messages[groupId] = items;
      } else {
        messages = { ...messages, [groupId]: existingItems.concat(items) };
      }

      messagesLoaded = { ...messagesLoaded, [groupId]: true };

      return {
        ...state,
        messages: { ...messages },
        messagesLoaded,
      };
    }

    case REMOVE_CHAT_MESSAGE: {
      let { groupId, messageId } = action.payload;
      let messages = state.messages;
      let items = messages[groupId] || [];
      let index = items.findIndex((d) => d._id === messageId);
      if (index !== -1) {
        messageId[groupId] = [
          ...items.slice(0, index),
          ...items.slice(index + 1),
        ];
      }
      return {
        ...state,
        messages: { ...messages },
      };
    }

    case EDIT_CHAT_MESSAGE: {
      let { groupId, messageId, content } = action.payload;
      let messages = state.messages;
      let items = messages[groupId] || [];
      let index = items.findIndex((d) => d._id === messageId);
      if (index !== -1) {
        items[index] = { ...items[index], ...content };
      }
      messages[groupId] = [...items];
      return {
        ...state,
        messages: { ...messages },
      };
    }

    case ADD_CHAT_MESSAGE: {
      let { groupId, message } = action.payload;
      let messages = state.messages;
      let items = messages[groupId] || [];
      messages = { ...messages, [groupId]: [message, ...items] };
      return {
        ...state,
        messages: { ...messages },
      };
    }

    case LOAD_CHAT_GROUPS: {
      let { groups = [] } = action.payload;
      return {
        ...state,
        groups,
        selectedGroup: groups[0] || null,
      };
    }

    case ADD_CHAT_GROUP: {
      let { group } = action.payload;
      let { groups = [] } = state;
      let index = groups.findIndex((g) => g._id === group._id);
      if (index !== -1) {
        return {
          ...state,
        };
      }

      groups.push({ ...group });
      return {
        ...state,
        groups: [...groups],
      };
    }

    case REMOVE_CHAT_GROUP: {
      let { groupId } = action.payload;
      let { groups, messages } = state;
      let index = groups.findIndex((g) => g._id === groupId);
      if (index === -1) {
        groups.splice(index, 1);
        delete messages[groupId];
      }
      return {
        ...state,
        groups: [...groups],
        messages: { ...groups },
      };
    }

    default:
      return state;
  }
}
