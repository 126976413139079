import React from "react";
import Icon from "../../icons/Icon";
import DocsRowMenu from "./DocsRowMenu";
import DateTime from "../../widgets/DateTime";
import DeleteIcon from "../../icons/DeleteIcon";
import { doDeleteDoc } from "../../../state/actions/docs";
import { moveOrCopy } from "../../../state/actions/group";
import { connect } from "react-redux";

const DocTitle = ({ groupId, doc, startEdit, doDeleteDoc, onDocDelete }) => {
  const deleteDoc = () => {
    doDeleteDoc(groupId, doc._id);
    onDocDelete();
  };

  return (
    <div className="d-flex justify-content-between notes-title">
      <div className="font-bold">{doc.title}</div>

      <div className="mb-2 dfjbac">
        <small className="text-muted">
          <span>{doc.user.name}</span>
          <span className="mx-1">|</span>
          <DateTime value={new Date(doc.dateCreated)} />
        </small>
      </div>
      <div>
        {startEdit && (
          <a onClick={startEdit}>
            <Icon name="edit_note" />
          </a>
        )}
        <span className="text-danger" onClick={deleteDoc}>
          <DeleteIcon className="ml-2" />
        </span>
      </div>
    </div>
  );
};

const actions = {
  doDeleteDoc: doDeleteDoc,
};

export default connect(null, actions)(DocTitle);
