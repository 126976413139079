import React, {useEffect} from "react";
import {getFile} from "../../../services/FileService";
import FilesView from "./FilesView";
import {connect} from "react-redux";
import {fetchFiles} from "../../../state/actions/files";
import {useParams} from "react-router-dom";

function FilesContainer(props) {
  const { groupId } = useParams();
  let filesLoaded = false;

  useEffect(() => {
    if (props.contentLoaded || filesLoaded) {
      return;
    }
    filesLoaded = true;
    props.loadFiles(groupId);
  }, [groupId]);

  const openFile = (fileId) => {
    return getFile(groupId, fileId);
  };

  return (
    <FilesView
      {...props}
      groupId={groupId}
      files={props.files}
      openFile={openFile}
    />
  );
}

const actions = {
  loadFiles: fetchFiles,
};

const data = (state) => {
  let contents = state.group.contents || {};
  const type = "files";
  return {
    files: contents[type],
    contentLoaded: !!state.group.contentLoaded[type],
  };
};

export default connect(data, actions)(FilesContainer);
