import React, { useEffect } from 'react';
import { connectToNamespace, disconnectFromNamespace } from "./Socket";
import { useDispatch } from "react-redux";
import {ADD_CONTENT, REMOVE_CONTENT} from "../state/actionTypes";

const NAMESPACES = ['tasks', 'files', 'docs'];

export const SocketProvider = ({ children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const namespaceConnections = {};

        NAMESPACES.forEach((namespace) => {
            const socket = connectToNamespace(namespace);
            namespaceConnections[namespace] = socket;

            const evt = namespace.slice(0, -1)
            // create event
            socket.on(`${evt}:create`, (data) => {
                console.log('create event', data);
                dispatch({
                    type: ADD_CONTENT,
                    payload: {
                        group: data.group,
                        item: data.item,
                        type: data.contentType,
                    },
                });
            });

            // delete event
            socket.on(`${evt}:delete`, (data) => {
                console.log('delete event', data);
                dispatch({
                    type: REMOVE_CONTENT,
                    payload: {
                        itemId: data.contentId,
                        type: data.contentType
                    },
                });
            });
        });

        return () => {
            NAMESPACES.forEach((namespace) => {
                disconnectFromNamespace(namespace);
            });
        };
    }, [dispatch]);

    return <>{children}</>;
};

export default SocketProvider;
