import {ADD_CONTENT, EDIT_CONTENT, LOAD_CONTENTS, REMOVE_CONTENT,} from "../actionTypes";
import {deleteFile, getFiles, updateFile, uploadFiles,} from "../../services/FileService";

export const doUploadFiles = (groupId, files) => async (dispatch) => {
  const response = await uploadFiles(groupId, files);
  let data = response.data || [];
  data.forEach((d) => {
    dispatch({
      type: ADD_CONTENT,
      payload: {
        groupId,
        item: d,
        type: "files",
      },
    });
  });
};

export const fetchFiles = (groupId) => async (dispatch) => {
  const files = await getFiles(groupId);
  dispatch({
    type: LOAD_CONTENTS,
    payload: { groupId, items: files, type: "files" },
  });
};

export const doUpdateFile = (groupId, fileId, data) => async (dispatch) => {
  const response = await updateFile(groupId, fileId, data);
  dispatch({
    type: EDIT_CONTENT,
    payload: { groupId, itemId: fileId, type: "files", content: response.data },
  });
};

export const doDeleteFile = (groupId, fileId) => async (dispatch) => {
  const response = await deleteFile(groupId, fileId);
  dispatch({
    type: REMOVE_CONTENT,
    payload: { groupId, itemId: fileId, type: "files" },
  });
};
