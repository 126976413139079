import "./styles.css";

import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import {
  EditorContent,
  EditorProvider,
  useCurrentEditor,
  useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useEffect, useRef, useState } from "react";
import MenuBar from "./MenuBar";
import MinimalMenuBar from "./MinimalMenuBar";
import { Mention } from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";

import suggestion from "./mentions/suggestions";
import { getGroupMembers } from "../../../../services/GroupService";

export default ({
  groupId,
  minimal,
  content,
  setEditor,
  setContent,
  placeholder = "",
  className,
  noInitialFocus,
}) => {
  const [members, setMembers] = useState([]);
  useEffect(() => {
    loadMembers();
  }, []);

  const loadMembers = async () => {
    if (groupId) {
      const members = await getGroupMembers(groupId);
      setMembers(members.map((m) => m.name));
    }
  };

  const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] }),
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
    }),
    Mention.configure({
      HTMLAttributes: {
        class: "mention",
      },
      suggestion: {
        ...suggestion,
        items: ({ query, editor }) =>
          editor.options.editorProps.items.filter((item) =>
            item.toLowerCase().startsWith(query.toLowerCase()),
          ),
        // .slice(0, 5)
      },
    }),
    Placeholder.configure({
      placeholder,
    }),
  ];

  const editor = useEditor({
    extensions,
    content,
    autofocus: noInitialFocus ? false : "end",
    onUpdate: ({ editor }) => {
      if (setContent) {
        setContent(editor.getHTML());
      }
    },
    editorProps: { items: members },
  });

  useEffect(() => {
    if (setEditor) {
      setEditor(editor);
    }
  }, []);

  if (!editor) {
    return null;
  }

  return (
    <div className="white-box">
      <div
        style={{
          borderBottom: "1px solid #e2e2e2",
          paddingBottom: 5,
          marginBottom: 10,
        }}
      >
        {minimal ? (
          <MinimalMenuBar editor={editor} />
        ) : (
          <MenuBar editor={editor} />
        )}
      </div>

      <EditorContent editor={editor} className={className || "full"} />

      {/*<EditorProvider*/}
      {/*  slotBefore={*/}
      {/*    <div*/}
      {/*      style={{*/}
      {/*        borderBottom: "1px solid #e2e2e2",*/}
      {/*        paddingBottom: 5,*/}
      {/*        marginBottom: 10,*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {minimal ? <MinimalMenuBar /> : <MenuBar />}*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*  extensions={extensions}*/}
      {/*  content={content}*/}
      {/*  editorProps={{ items: suggestions }}*/}
      {/*></EditorProvider>*/}
    </div>
  );
};
