import React, { Component } from "react";
import FilesGrid from "./FilesGrid";
import ModalPopup from "../../modal/ModalPopup";
import { BarLoader } from "react-spinners";
import ExpandedFilesView from "./ExpandedFilesView";
import FilesUploaderInline from "./FilesUploaderInline";
import FileViewerInline from "./FileViewerInline";
import {fetchFiles} from "../../../state/actions/files";

export default class FilesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  toggleExpand = () => {
    this.setState({ groupExpanded: !this.state.groupExpanded });
  };

  openFile = (file) => {
    this.setState({ loading: true });
    this.props
      .openFile(file._id)
      .then((fileURL) => {
        this.setState({ file, fileURL, loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  };

  closeFile = () => {
    this.setState({ file: null, fileURL: null });
  };


  updateFiles = (updatedFiles) => {
    this.setState({ files: updatedFiles });
  };

  render() {
    let { files, groupId, minimal } = this.props;
    let { groupExpanded, file, fileURL, loading } = this.state;

    return (
      <div className="col-md-8 offset-md-2">
        {minimal ? (
          <div
            className="p-3"
            style={{ height: "calc(100vh - 200px)", overflow: "auto" }}
          >
            <div className="feed-activity-list">
              <FilesGrid
                  files={files}
                  openFile={this.openFile}
                  updateFiles={this.updateFiles}
                  inline
              />
            </div>
          </div>
        ) : (
          <div
            className={`float-e-margins`}
            style={this.props.containerStyle || {}}
          >
            <div className="d-flex">
              <h3>Files</h3>
              <div className="d-flex align-items-center"></div>
            </div>
            <div style={this.props.containerStyle || {}}>
              <div className="file-manager">
                <div style={{ width: "100%" }}>
                  <FilesUploaderInline groupId={groupId} inline />
                </div>
              </div>

              <BarLoader
                color={"#2d85c6"}
                loading={loading}
                css={"display: block; margin: 0 auto; border-color: red;"}
                size={150}
              />

              <div className="d-flex flex-wrap">
                <FilesGrid
                    files={files}
                    openFile={this.openFile}
                    updateFiles={this.updateFiles}
                    inline
                />
              </div>
              <div className="clearfix" />
            </div>
          </div>
        )}

        {fileURL && (
          <ModalPopup show={true} size="xl" handleClose={this.closeFile}>
            <FileViewerInline
              file={file}
              fileURL={fileURL}
              groupId={groupId}
              close={this.closeFile}
            />
          </ModalPopup>
        )}

        {groupExpanded && (
          <ModalPopup
            show={true}
            fullscreen={true}
            handleClose={this.toggleExpand}
          >
            <ExpandedFilesView {...this.props} onPopup={true} />
          </ModalPopup>
        )}
      </div>
    );
  }
}
