import React, { useEffect, useRef, useState } from "react";
import TaskDescriptionForm from "./TaskDescriptionForm";

const TaskDescription = ({ description, save, cancel }) => {
  const descRef = useRef();

  const saveDescription = () => {
    console.log()
    save(descRef.current);
  };

  return (
    <div className="form-control" style={{ borderRadius: 4 }}>
      <TaskDescriptionForm ref={descRef} description={description} />

      <div className="d-flex justify-content-end">
        <button className="btn btn-white btn-sm" onClick={cancel}>
          Cancel
        </button>
        <button className="btn btn-dark btn-sm ml-2" onClick={saveDescription}>
          Save
        </button>
      </div>
    </div>
  );
};

export default TaskDescription;
