import { formatAMPM } from "../../../utils/Utils";
import ChatRowMenu from "./ChatRowMenu";
import React, { useState } from "react";
import { connect } from "react-redux";
import { doUpdateMessage } from "../../../state/actions/chat";
import RichTextView from "../docs/Editor/RichTextView";
import FilePreview from "../../widgets/FilePreview";
import DateTime from "../../widgets/DateTime";

const ChatMessageView = ({
  message,
  isDayDifference,
  isSelf,
  groupId,
  updateMessage,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [edit, setEdit] = useState(false);
  const [contentBeingEdited, setContentBeingEdited] = useState(null);

  const toggleEditing = () => {
    let newEdit = !edit;
    setEdit(newEdit);
    if (newEdit) {
      setContentBeingEdited(message.content);
    }
  };

  const onStartHover = () => {
    setShowMenu(true);
  };

  const onEndHover = () => {
    setShowMenu(false);
  };

  const saveEditedContent = () => {
    updateMessage(groupId, message._id, {
      ...message,
      content: contentBeingEdited,
    });
    toggleEditing();
  };

  return (
    <div>
      {isDayDifference && (
        <div className="text-center my-2">
          <span
            style={{
              paddingRight: 8,
              paddingLeft: 8,
              fontSize: 12,
              top: 10,
              backgroundColor: "white",
              display: "inline-block",
              position: "relative",
              // color: "#212529",
            }}
          >
            {isDayDifference}
          </span>
          <div
            style={{ height: "0.009rem", backgroundColor: "#eff1f5" }}
            className="line"
          ></div>
        </div>
      )}

      <div
        className="mb-3"
        className={showMenu && !edit ? "msg-content-active" : ""}
        style={{ padding: "5px" }}
        onMouseEnter={onStartHover}
        onMouseLeave={onEndHover}
        onTouchStart={onStartHover}
        onTouchEnd={onEndHover}
      >
        <div className="d-flex align-items-center mb-1">
          <div style={{ fontStyle: 16, fontWeight: 600 }}>
            {message.user.name}
          </div>
          <div className="small text-muted ml-2">
            {/*{formatAMPM(new Date(message.updatedOn))}*/}
            <DateTime value={new Date(message.updatedOn)} />
          </div>
        </div>
        <div className="msg-content dfjbac">
          {edit ? (
            <div
              style={{
                width: "100%",
                border: "1px solid rgb(204, 204, 204)",
                padding: 10,
                borderRadius: 4,
              }}
            >
              <input
                style={{ border: 0 }}
                type="text"
                className="form-control"
                value={contentBeingEdited}
                onChange={(e) => setContentBeingEdited(e.target.value)}
              />
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-white btn-sm"
                  onClick={toggleEditing}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-dark btn-sm ml-2"
                  onClick={saveEditedContent}
                >
                  Save
                </button>
              </div>
            </div>
          ) : (
            <div>
              <RichTextView content={message.content} />
              <div className="d-flex">
                {message.files &&
                  message.files.map((file, index) => (
                    <div className="mr-2" key={index}>
                      <FilePreview file={file} groupId={message.group} />
                    </div>
                  ))}
              </div>
            </div>
          )}

          {showMenu && !edit && (
            <div style={{ height: 10 }}>
              <ChatRowMenu
                groupId={groupId}
                messageId={message._id}
                startEditing={toggleEditing}
                userId={message.user.id}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const actions = {
  updateMessage: doUpdateMessage,
};

export default connect(null, actions)(ChatMessageView);

// <div className={`chat-msg`}>
// <div className="chat-msg-profile">
//   <div className="chat-msg-date">
//     {" "}
//     {formatAMPM(new Date(message.updatedOn))}
//   </div>
// </div>
// <div className="chat-msg-content">
//   <div className="chat-msg-text">
//     <div className="message-author small mb-1">
//       {message.user.name}
//     </div>
//     {message.content}
//   </div>
// </div>
// </div>
