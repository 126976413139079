import React, { Component } from "react";
import AnswerEditor from "./AnswerEditor";
import Icon from "../../icons/Icon";
import { doUpdateAnswer } from "../../../state/actions/question";
import { connect } from "react-redux";
import DateTime from "../../widgets/DateTime";
import Avatar from "../../widgets/Avatar";
import { getNameInitials } from "../../../utils/Utils";
import RichTextView from "../docs/Editor/RichTextView";
import QuestionRowMenu from "./QuestionRowMenu";
import DeleteIcon from "../../icons/DeleteIcon";

function QuestionTitle({ question, startEdit, startDelete }) {
  return (
    <div>
      <div className="dfjbac">
        <div className="dfac">
          <Avatar className="mr-2" text={getNameInitials(question.user.name)} />
          <div style={{ fontSize: "16px", fontWeight: "500" }}>
            {question.user.name}
          </div>
        </div>
        <div className="d-flex">
          <div className="text-muted small mx-3">
            <DateTime value={new Date(question.dateCreated)} />
          </div>
          <div>
            <a onClick={startEdit} className="mx-1">
              <Icon name="edit_note" />
            </a>

            <a onClick={startDelete}>
              <span className="text-danger">
                <DeleteIcon className="mr-h" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionTitle;
