import "./styles.css";

import { BubbleMenu, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useEffect } from "react";
import Icon from "../../../icons/Icon";

export default ({ content, setEditor, submitContent }) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content,
    // onUpdate: ({ editor }) => {
    //   setContent(editor.getHTML());
    // },
    autofocus: "end",
    onKeyDown: (a, b) => {
      console.log(a, b);
    },
    editorProps: {
      handleKeyDown: (view, event) => {
        if (event.key === "Enter" && !event.shiftKey && submitContent) {
          submitContent();
          return true;
        }
        return false;
      },
    },
  });

  useEffect(() => {
    setEditor(editor);
  }, []);

  return (
    <>
      {editor && (
        <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
          <div className="bubble-menu">
            <button
              onClick={() => editor.chain().focus().toggleBold().run()}
              className={
                editor.isActive("bold") ? "tip-button is-active" : "tip-button "
              }
            >
              <Icon fontSize={18} name="format_bold" />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleItalic().run()}
              className={
                editor.isActive("italic")
                  ? "tip-button  is-active"
                  : "tip-button "
              }
            >
              <Icon fontSize={18} name="format_italic" />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleStrike().run()}
              className={
                editor.isActive("strike")
                  ? "tip-button is-active"
                  : "tip-button "
              }
            >
              <Icon fontSize={18} name="strikethrough_s" />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              className={
                editor.isActive("bulletList")
                  ? "tip-button tip-is-active"
                  : "tip-button"
              }
            >
              <Icon fontSize={18} name="list" />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              className={
                editor.isActive("orderedList")
                  ? "tip-button tip-is-active"
                  : "tip-button"
              }
            >
              <Icon fontSize={18} name="format_list_numbered" />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleCodeBlock().run()}
              className={
                editor.isActive("codeBlock")
                  ? "tip-button tip-is-active"
                  : "tip-button"
              }
            >
              <Icon fontSize={18} name="code" />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleBlockquote().run()}
              className={
                editor.isActive("blockquote")
                  ? "tip-button tip-is-active"
                  : "tip-button"
              }
            >
              <Icon fontSize={18} name="format_quote" />
            </button>
          </div>
        </BubbleMenu>
      )}
      <EditorContent editor={editor} className="bubble" />
    </>
  );
};
