import TextEditor from "../content/docs/Editor/TextEditor";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getGroups } from "../../services/GroupService";
import { addFeed } from "../../state/actions/feed";
import { connect } from "react-redux";

const FeedEditor = (props) => {
  const [type, setType] = useState(null);
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState(null);
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [files, setFiles] = useState(null);

  useEffect(() => {
    loadGroups();
  }, []);

  const loadGroups = async () => {
    let groups = await getGroups("REGULAR");
    groups = groups.map((group) => ({
      value: group._id,
      label: group.name,
    }));
    setGroups(groups);
  };

  const onChangeContent = (content) => {
    setContent(content);
  };

  const addFeed = () => {
    //todo show error
    props
      .createFeed({
        type: type.value,
        groupId: group.value,
        content,
        files,
        title,
      })
      .then((response) => {
        props.onCreateFeed();
      });
  };

  const typeOptions = [
    { value: "media-post", label: "Post" },
    { value: "task", label: "Task" },
    { value: "doc", label: "Doc" },
    { value: "question", label: "Question" },
  ];

  const handleFilesChange = (e) => {
    const files = [];
    const nonArrayFiles = e.target.files;
    for (let i = 0; i < nonArrayFiles.length; i++) {
      files.push(nonArrayFiles[i]);
    }
    setFiles(files);
  };

  return (
    <div>
      <div>
        <div style={{ fontWeight: 500 }} className="mb-1">
          Type
        </div>
        <div className="mb-3">
          <Select
            options={typeOptions}
            onChange={(value) => setType(value)}
            value={type}
            isClearable={false}
          />
        </div>

        <div style={{ fontWeight: 500 }} className="mb-1">
          Group
        </div>
        <div className="mb-3">
          <Select
            options={groups}
            onChange={(value) => setGroup(value)}
            value={group}
            isClearable={false}
          />
        </div>

        {type && type.value === "task" && (
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              style={{ height: "40px" }}
              placeholder="What needs to be done?"
            />
          </div>
        )}

        <div style={{ fontWeight: 500 }} className="mb-1">
          Content
        </div>
        <div className="form-group">
          <TextEditor
            content={content}
            setContent={onChangeContent}
            placeholder={
              type === "Task"
                ? "Enter optional task description here"
                : "Type content here..."
            }
            className="post"
            minimal
          />
        </div>
      </div>

      <div className="file-manager mt-4">
        <button
          className="btn btn-rounded btn-dark btn-sm"
          style={{ borderRadius: "100%", padding: "5px 8px" }}
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-photo"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1="15" y1="8" x2="15.01" y2="8"></line>
            <rect x="4" y="4" width="16" height="16" rx="3"></rect>
            <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5"></path>
            <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2"></path>
          </svg>
          <span className="MuiTouchRipple-root css-w0pj6f"></span>
        </button>
        <label className="ml-2" tabIndex="0" role="button">
          <input
            hidden
            accept="image/*"
            multiple=""
            type="file"
            onChange={handleFilesChange}
          />
          Photo / Video
          {files && (
            <span className="ml-2">
              {files.map((f, index) => f.name).join(", ")}
            </span>
          )}
        </label>
      </div>

      <div className="d-flex align-items-center justify-content-end m-t-md">
        <button type="submit" className="btn btn-dark" onClick={addFeed}>
          Post
        </button>
      </div>
    </div>
  );
};

const actions = {
  createFeed: addFeed,
};

export default connect(null, actions)(FeedEditor);
