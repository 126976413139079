import { io } from 'socket.io-client';
import Env from "../services/Env";
import {getUser} from "../services/DataService";

const BASE_URL = Env.serverURL;
const socketConnections = {};

export const connectToNamespace = (namespace) => {
    const ns = `${BASE_URL}/${namespace}`;
    if (!socketConnections[ns]) {
        socketConnections[ns] = io(ns, {
            transports: ['websocket'],
            reconnection: true,
            auth: {
                token: getUser().token
            }
        });
    }
    return socketConnections[ns];
};

export const disconnectFromNamespace = (namespace) => {
    const ns = `${BASE_URL}/${namespace}`;
    if (socketConnections[ns]) {
        socketConnections[ns].disconnect();
        delete socketConnections[ns];
    }
};
