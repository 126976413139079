import React from "react";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { RowMenu, RowMenuItem } from "../../widgets/RowMenu";
import { connect } from "react-redux";
import { moveOrCopy } from "../../../state/actions/group";
import DeleteIcon from "../../icons/DeleteIcon";
import CopyIcon from "../../icons/CopyIcon";
import MoveIcon from "../../icons/MoveIcon";
import { deleteQuestion } from "../../../services/ContentService";

class QuestionRowMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  deleteQuestion = () => {
    let { question, groupId, listId, deleteQuestion } = this.props;
    deleteQuestion(listId, groupId, question._id);
  };

  copy = () => {
    this.moveOrCopy("copy");
  };

  move = () => {
    this.moveOrCopy("move");
  };

  moveOrCopy = action => {
    let { question: item, groupId, listId } = this.props;
    this.props.moveOrCopy({
      listId,
      groupId,
      item,
      action,
      from: "question"
    });
  };

  render() {
    return (
      <RowMenu>
        <RowMenuItem>
          <a onClick={this.copy}>
            <CopyIcon className="mr-h" />
            Copy
          </a>
        </RowMenuItem>
        <RowMenuItem>
          <a onClick={this.move}>
            <MoveIcon className="mr-h" />
            Move
          </a>
        </RowMenuItem>

        <RowMenuItem onClick={this.deleteQuestion}>
          <span className="text-danger">
            <DeleteIcon className="mr-h" />
            Delete
          </span>
        </RowMenuItem>
      </RowMenu>
    );
  }
}

const actions = {
  deleteQuestion,
  moveOrCopy
};

export default connect(
  null,
  actions
)(QuestionRowMenu);
