import {
  addChatMessage,
  deleteChatMessage,
  getContent,
  updateChatMessage,
} from "../../services/ContentService";
import {
  ADD_CHAT_GROUP,
  ADD_CHAT_MESSAGE,
  EDIT_CHAT_MESSAGE,
  LOAD_CHAT_GROUPS,
  LOAD_CHAT_MESSAGES,
  REMOVE_CHAT_MESSAGE,
  SET_CHAT_GROUP,
} from "../actionTypes";
import { createGroup, getGroups } from "../../services/GroupService";
import { registerMultipleGroups } from "../../services/Socket";

export const fetchChatGroups = () => async (dispatch) => {
  const groups = await getGroups("CHAT");
  dispatch({
    type: LOAD_CHAT_GROUPS,
    payload: {
      groups,
    },
  });
  //registerMultipleGroups(groups.map((group) => group._id));
};

export const selectChatGroup = (group) => async (dispatch) => {
  dispatch({
    type: SET_CHAT_GROUP,
    payload: {
      group,
    },
  });
};

export const createChatGroup = (group) => async (dispatch) => {
  const response = await createGroup({ ...group, type: "CHAT" });
  dispatch({
    type: ADD_CHAT_GROUP,
    payload: {
      group: response,
    },
  });
  dispatch({
    type: SET_CHAT_GROUP,
    payload: {
      group: response,
    },
  });
};

export const fetchMessages = (groupId) => async (dispatch) => {
  const messages = await getContent(groupId, "chat");
  dispatch({
    type: LOAD_CHAT_MESSAGES,
    payload: {
      items: messages,
      groupId,
    },
  });
};

export const doAddMessage = (groupId, data) => async (dispatch) => {
  const response = await addChatMessage(groupId, data);
  dispatch({
    type: ADD_CHAT_MESSAGE,
    payload: {
      groupId,
      message: response.data,
    },
  });
};

export const doUpdateMessage =
  (groupId, messageId, data) => async (dispatch) => {
    const response = await updateChatMessage(groupId, messageId, data);
    dispatch({
      type: EDIT_CHAT_MESSAGE,
      payload: {
        groupId,
        messageId,
        content: response.data,
      },
    });
  };

// export const fetchMessages = (groupId) => async (dispatch) => {
//   const messages = await getContent(groupId, "chat");
//   dispatch({
//     type: LOAD_CONTENTS,
//     payload: { groupId, items: messages, type: "chat" },
//   });
// };

export const doDeleteMessage = (groupId, messageId) => async (dispatch) => {
  const response = await deleteChatMessage(groupId, messageId);
  dispatch({
    type: REMOVE_CHAT_MESSAGE,
    payload: { groupId, itemId: messageId },
  });
};
