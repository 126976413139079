import React, { useEffect, useState } from "react";
import { getGroups } from "../../services/GroupService";
import { getFile } from "../../services/FileService";
import ModalPopup from "../modal/ModalPopup";
import { getUser } from "../../services/DataService";
import FileViewerInline from "../content/files/FileViewerInline";
import FeedView from "./FeedView";
import TaskViewModal from "../content/tasks/TaskViewModal";

const FeedsView = ({ feeds = [] }) => {
  const [groups, setGroups] = useState([]);
  const [fileToShow, setFileToShow] = useState(null);
  const [taskToView, setTaskToView] = useState(null);

  useEffect(() => {
    loadGroups();
  }, []);

  const loadGroups = async () => {
    const groups = await getGroups("REGULAR");
    setGroups(groups);
  };

  const openFile = async (file) => {
    const fileURL = await getFile(file.group, file._id);
    setFileToShow({ fileURL, file });
  };

  const closeFile = () => setFileToShow(null);

  const getGroupName = (id) => {
    const filtered = groups.filter((group) => group._id === id);
    if (filtered && filtered[0]) {
      return filtered[0].name;
    }
    return "";
  };

  const startViewTask = (task = -1) => setTaskToView(task);
  const cancelTaskView = () => setTaskToView(null);

  return (
    <div className="d-flex justify-content-center">
      <div className="col-md-8">
        <div>
          <h3 className="text-center mb-0">Whats Happening...</h3>

          <div className="p-1 pt-3">
            {feeds.map((feed, index) => {
              return (
                <FeedView
                  key={feed._id + "_" + index}
                  feed={feed}
                  groupName={getGroupName(feed.group).toUpperCase()}
                  openFile={openFile}
                  openTask={() => startViewTask(feed)}
                />
              );
            })}
          </div>
        </div>
      </div>
      {fileToShow && (
        <ModalPopup show={true} size="xl" handleClose={closeFile}>
          <FileViewerInline
            file={fileToShow.file}
            fileURL={fileToShow.fileURL}
            groupId={fileToShow.file.group}
            close={closeFile}
          />
        </ModalPopup>
      )}

      {taskToView != null && (
        <TaskViewModal
          task={taskToView}
          groupId={taskToView.group}
          toggleEditTask={cancelTaskView}
        />
      )}
    </div>
  );
};

export default FeedsView;
