import React, { useState, forwardRef, useImperativeHandle } from "react";
import TextEditor from "../docs/Editor/TextEditor";

const TaskDescriptionForm = forwardRef(function (props, ref) {
  const [description, setDescription] = useState(props.description || "");

  const setContent = (content) => {
    setDescription(content);
  };

  useImperativeHandle(ref, function getDescription() {
    return description;
  });

  return (
    <TextEditor
      content={description}
      setContent={setContent}
      className="minimal"
      minimal
    />
  );
});

export default TaskDescriptionForm;
