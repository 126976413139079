import React from "react";
import DocTitle from "./DocTitle";
import DocContent from "./DocContent";

export default ({ groupId, doc, startEdit, onDocDelete }) => {
  return (
    <div
      className="ibox "
      style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
      key={doc._id}
    >
      <div className="ibox-title">
        <DocTitle
          doc={doc}
          groupId={groupId}
          startEdit={startEdit}
          onDocDelete={onDocDelete}
        />
      </div>

      <div className="ibox-content">
        <DocContent doc={doc} />
      </div>
    </div>
  );
};
