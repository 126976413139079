import React, { Component, useEffect } from "react";
import "./home.css";
import HomeBanner from "./HomeBanner";
import WhatIsIt from "./WhatIsIt";
import Footer from "./Footer";
import Features from "./Features";
import UseCases from "./UseCases";
import { getUser } from "../../services/DataService";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    let user = getUser();
    if (user) {
      navigate("/home");
    }
  }, []);

  return (
    <div>
      <HomeBanner />
      <div
        style={
          {
            // backgroundImage:
            //   "linear-gradient( 135deg, #5EFCE8 10%, #736EFE 100%)"
            // background:
            // "linear-gradient(90deg, hsla(228, 17%, 53%, 1) 0%, hsla(229, 28%, 88%, 1) 100%)"
            // "linear-gradient(90deg, hsla(213, 62%, 45%, 1) 0%, hsla(203, 89%, 71%, 1) 50%, hsla(0, 0%, 96%, 1) 100%)"
            // "linear-gradient(90deg, hsla(288, 20%, 75%, 1) 0%, hsla(197, 65%, 42%, 1) 100%)"
          }
        }
      >
        <WhatIsIt />
        {/*<hr style={{ height: 2, color: "#fff" }} />*/}
        {/*<About />*/}
        <Features />
        <div className="bg-white d-flex justify-content-center">
          <hr style={{ width: "50%", height: 2 }} />
        </div>
        <UseCases />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
