import React, { useEffect, useState } from "react";
import "./Chats.css";
import NewChatsList from "./NewChatsList";
import GroupChatContent from "./GroupChatContent";
import GroupChatMessageForm from "./GroupChatMessageForm";
import ChatFilesContainer from "./ChatFilesContainer";

export default function ChatsView({
  groups,
  selectedGroup,
  messages,
  selectChatGroup,
  loadMessages,
  messagesLoaded,
}) {
  const [tab, setTab] = useState("messages");

  useEffect(() => {
    if (selectedGroup) {
      if (!messagesLoaded) {
        loadMessages(selectedGroup._id);
      }
    }
    setTab("messages");
  }, [selectedGroup]);

  return (
    <div className="d-flex justify-content-center">
      <div className="col-md-12">
        <div
          className="ibox ibox-content"
          style={{
            borderTopRightRadius: 4,
            borderTopLeftRadius: 4,
            marginBottom: 10,
          }}
        >
          <h3 className="mb-0">Messages</h3>
          <div className="p-1 pt-3">
            <div className="chat-app">
              <div className="wrapper row" style={{ flexWrap: "nowrap" }}>
                <div className="conversation-area " style={{ flex: 2 }}>
                  <NewChatsList
                    groups={groups}
                    select={(group) => {
                      selectChatGroup(group);
                    }}
                    selected={selectedGroup ? selectedGroup._id : null}
                  />
                </div>
                {selectedGroup && (
                  <div className="chat-area " style={{ flex: 9 }}>
                    <div className="tabs-container">
                      <ul className="nav nav-tabs d-flex border-bottom justify-content-between">
                        <li onClick={() => setTab("messages")}>
                          <a
                            className={`nav-link ${tab === "messages" ? "active" : ""}`}
                            style={{ fontWeight: 500 }}
                          >
                            Messages
                          </a>
                        </li>
                        <li onClick={() => setTab("files")}>
                          <a
                            className={`nav-link ${tab === "files" ? "active" : ""}`}
                            style={{ fontWeight: 500 }}
                          >
                            Files
                          </a>
                        </li>
                      </ul>
                      {tab === "messages" && (
                        <div>
                          <div className="chat-area-main">
                            <GroupChatContent
                              group={selectedGroup}
                              messages={messages}
                            />
                          </div>
                          <GroupChatMessageForm groupId={selectedGroup._id} />
                        </div>
                      )}
                      {tab === "files" && (
                        <div>
                          <ChatFilesContainer group={selectedGroup} minimal />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
