import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { getGroupMembers } from "../../../services/GroupService";
import { doDeleteTask, doEditTask } from "../../../state/actions/task";
import { connect } from "react-redux";
import TaskDescription from "./TaskDescription";
import TaskCommentForm from "./TaskCommentForm";
import CommentsView from "./CommentsView";
import RichTextView from "../docs/Editor/RichTextView";
import Icon from "../../icons/Icon";
import SubTaskCreator from "./SubTaskCreator";
import { preProcessTask } from "./utils";
import {
  addTaskComment,
  deleteTaskComment,
  getContent,
  getSubContent,
  updateTaskComment,
} from "../../../services/ContentService";
import TaskTitle from "./TaskTitle";

const TaskView = ({ groupId, taskId, editTask, startEdit, deleteTask }) => {
  const [task, setTask] = useState(null);
  const [members, setMembers] = useState([]);
  const [descEdit, setDescEdit] = useState(false);
  const [commentForm, setCommentForm] = useState(false);
  const commentRef = useRef();
  const [subTaskForm, setSubTaskForm] = useState(false);
  const [comments, setComments] = useState([]);
  const [titleEdit, setTitleEdit] = useState(false);
  const [editDueDate, setEditDueDate] = useState(false);
  const [editAssignee, setEditAssignee] = useState(false);
  let commentsLoaded = false;

  useEffect(() => {
    loadMembers();
    fetchTask();
    fetchComments();
  }, [taskId]);

  const fetchTask = async () => {
    let task = await getContent(groupId, "task", taskId);
    setTask(preProcessTask(task));
  };

  const fetchComments = async () => {
    if (!commentsLoaded) {
      commentsLoaded = true;
      const comments = await getSubContent(groupId, "task", taskId, "comment");
      setComments(comments);
    }
  };

  const loadMembers = async () => {
    const members = await getGroupMembers(groupId);
    setMembers(members.map((m) => ({ value: m._id, label: m.name })));
  };

  const handleDescriptionChange = (value) => {
    setInTask("description", value);
    setDescEdit(false);
  };

  const handleAssigneeChange = (option) => {
    setInTask("assignee", option || null);
  };

  const handleDueOnChange = (e) => {
    setInTask("dueOn", e.target.value);
    setEditDueDate(false);
  };

  const closeTask = () => {
    const newStatus = task.status === "Open" ? "Completed" : "Open";
    setInTask("status", newStatus);
  };

  const setInTask = (key, val) => {
    task[key] = val;
    setTask({ ...task });
    updateTask(task);
  };

  const updateTask = (task) => {
    task.type = task.type ? task.type.value : null;
    task.priority = task.priority ? task.priority.value : null;
    task.assignee = task.assignee
      ? { id: task.assignee.value, name: task.assignee.label }
      : null;
    task.reportedBy = task.reportedBy
      ? { id: task.reportedBy.value, name: task.reportedBy.label }
      : null;
    task.dueOn = task.dueOn ? new Date(task.dueOn) : null;
    editTask(groupId, task._id, {
      ...task,
    });
  };

  const saveComment = async () => {
    const comment = commentRef.current;
    if (comment) {
      const response = await addTaskComment(groupId, task._id, {
        content: comment,
      });
      setComments([response.data, ...comments]);
      setCommentForm(false);
    }
  };

  const updateComment = async (index, commentId, content) => {
    if (!content) {
      return;
    }
    const response = await updateTaskComment(groupId, task._id, commentId, {
      content,
    });
    setComments([
      ...comments.slice(0, index),
      response.data,
      ...comments.slice(index + 1),
    ]);
    setCommentForm(false);
  };

  const deleteComment = async (comment, index) => {
    await deleteTaskComment(groupId, task._id, comment._id);
    setComments([...comments.slice(0, index), ...comments.slice(index + 1)]);
    setCommentForm(false);
  };

  const onCreateSubTask = (subtask) => {
    const subtasks = task.subtasks || [];
    setTask({ ...task, subtasks: [subtask, ...subtasks] });
    setSubTaskForm(false);
  };

  const startDelete = () => {
    deleteTask(groupId, task._id);
    setTask({ ...task, archived: true });
  };

  if (!task) {
    return null;
  }

  const { subtasks = [] } = task;

  return (
    <div className="d-flex flex-column" style={{ flexWrap: "nowrap" }}>
      <div className="dfjbac flex-fill">
        {titleEdit ? (
          <TaskTitle
            title={task.title}
            // save={handleTitleChange}
            cancel={() => setTitleEdit(false)}
          />
        ) : (
          <h2 onClick={() => setTitleEdit(true)}>
            {task.title || "Click here to add title"}
          </h2>
        )}

        {task.archived ? (
          <div className="badge badge-danger">ARCHIVED</div>
        ) : (
          <div>
            <button className="btn-white border-0" onClick={startDelete}>
              <Icon name="delete" fontSize={22} className="text-danger" />
            </button>
          </div>
        )}
      </div>
      <div className="dfjbac mb-3">
        <div className="dfac mr-2">
          <Icon
            name="person"
            fontSize={22}
            className="text-primary"
            tooltip="Assignee"
          />
          {editAssignee ? (
            <Select
              styles={{
                control: (base) => ({
                  ...base,
                  border: 0,
                  boxShadow: "none",
                }),
              }}
              options={members}
              value={task.assignee}
              onChange={handleAssigneeChange}
              autoFocus={true}
              isClearable={true}
            />
          ) : (
            <div className="ml-1" onClick={() => setEditAssignee(true)}>
              <span className="text-primary cp">
                {task.assignee ? task.assignee.label : "Add"}
              </span>
            </div>
          )}
        </div>
        <div className="dfac ml-2">
          <Icon
            name="calendar_month"
            fontSize={22}
            className="text-warning"
            tooltip="Due On"
          />
          {editDueDate ? (
            <input
              type="date"
              className="form-control"
              value={task.dueOn}
              onChange={handleDueOnChange}
            />
          ) : (
            <div className="ml-1" onClick={() => setEditDueDate(true)}>
              <span className="text-primary cp">
                {task.dueOn ? new Date(task.dueOn).toLocaleDateString() : "Add"}
              </span>
            </div>
          )}
        </div>

        <div className="dfac ml-2">
          <input
            type="checkbox"
            className="check-link"
            onChange={closeTask}
            checked={task.status === "Completed"}
            style={{ height: 18, width: 18 }}
          />
          <span className="ml-2">Closed</span>
        </div>
      </div>

      <div className="col-md-12">
        <div className="mb-3">
          {descEdit ? (
            <TaskDescription
              description={task.description}
              save={handleDescriptionChange}
              cancel={() => setDescEdit(false)}
            />
          ) : (
            <RichTextView
              onClick={() => setDescEdit(true)}
              content={
                task.description || "<p>Click here to add description </p>"
              }
            />
          )}
        </div>
        <hr />

        <div className="white-box">
          <div className="dfac">
            <h4 className="my-0">Subtasks</h4>
            {!subTaskForm && (
              <a
                className="link d-flex align-items-center ml-2"
                onClick={() => setSubTaskForm(true)}
              >
                {/*<Icon name="add" fontSize={18} />*/}
                create
              </a>
            )}
          </div>
          {subTaskForm && (
            <div className="my-2">
              <SubTaskCreator
                onCreate={onCreateSubTask}
                groupId={groupId}
                parentTaskId={taskId}
              />
            </div>
          )}

          {subtasks.map((subtask) => (
            <div className="my-2 border-bottom" key={subtask._id}>
              <a onClick={() => startEdit(subtask)} className="link">
                {subtask.title}
              </a>
            </div>
          ))}
        </div>

        <div className="white-box mt-3">
          <div className="dfac">
            <h4 className="my-0">Comments</h4>
            <a
              className="link d-flex align-items-center ml-2"
              onClick={() => setCommentForm(true)}
            >
              {/*<Icon name="add" fontSize={18} />*/}
              add
            </a>
          </div>

          <div className="my-2">
            {commentForm && (
              <div className="" style={{ borderRadius: 4 }}>
                <TaskCommentForm ref={commentRef} />
                <div className="d-flex justify-content-end mt-2">
                  <button
                    className="btn btn-white btn-sm"
                    onClick={() => setCommentForm(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-dark btn-sm ml-2"
                    onClick={saveComment}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </div>

          <CommentsView
            comments={comments}
            deleteComment={deleteComment}
            updateComment={updateComment}
          />
        </div>
      </div>
    </div>
  );
};

const actions = {
  editTask: doEditTask,
  deleteTask: doDeleteTask,
};

export default connect(null, actions)(TaskView);
