import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { getGroupMembers } from "../../../services/GroupService";
import TaskDescriptionForm from "./TaskDescriptionForm";
import MinimalTaskForm from "./MinimalTaskForm";

function TaskForm({ task: propsTask, groupId, submitTask }) {
  const [task, setTask] = useState(propsTask || { status: "Open" });
  const [members, setMembers] = useState([]);
  const descRef = useRef();
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  useEffect(() => {
    loadMembers();
  }, []);

  const loadMembers = async () => {
    const members = await getGroupMembers(groupId);
    setMembers(members.map((m) => ({ value: m._id, label: m.name })));
  };

  const handleTitleChange = (e) => {
    setInTask("title", e.target.value);
  };

  const handleTypeChange = (option) => {
    setInTask("type", option || null);
  };

  const handlePriorityChange = (option) => {
    setInTask("priority", option || null);
  };
  const handleStatusChange = (option) => {
    setInTask("status", option || null);
  };

  const handleAssigneeChange = (option) => {
    setInTask("assignee", option || null);
  };

  const handleReportedByChange = (option) => {
    setInTask("reportedBy", option || null);
  };

  const handleDueOnChange = (e) => {
    setInTask("dueOn", e.target.value);
  };

  const setInTask = (key, val) => {
    task[key] = val;
    setTask({ ...task });
  };

  const submit = (e) => {
    e.preventDefault();
    task.description = descRef.current;
    task.type = task.type ? task.type.value : undefined;
    task.priority = task.priority ? task.priority.value : undefined;
    task.status = task.status ? task.status.value : undefined;
    task.assignee = task.assignee
      ? { id: task.assignee.value, name: task.assignee.label }
      : undefined;
    task.reportedBy = task.reportedBy
      ? { id: task.reportedBy.value, name: task.reportedBy.label }
      : undefined;
    task.dueOn = task.dueOn ? new Date(task.dueOn) : undefined;

    submitTask(task);
  };

  if (!expanded) {
    return (
      <MinimalTaskForm
        task={task}
        toggleExpanded={toggleExpanded}
        submit={submit}
        handleTitleChange={handleTitleChange}
      />
    );
  }

  return (
    <form onSubmit={submit} className="form-horizontal">
      <div className="form-group">
        <label>Title</label>
        <input
          type="text"
          className="form-control"
          value={task.title}
          onChange={handleTitleChange}
          style={{ height: "40px" }}
        />
      </div>

      <div className="form-group">
        <label>Description</label>
        <div className="form-control" style={{ borderRadius: 4 }}>
          <TaskDescriptionForm ref={descRef} description={task.description} />
        </div>
      </div>

      <hr />

      <div className="dfjbac">
        <div className="form-group" style={{ flex: 1 }}>
          <label>Assignee</label>
          <Select
            options={members}
            value={task.assignee}
            onChange={handleAssigneeChange}
            isClearable={true}
          />
        </div>
        <div className="form-group ml-2" style={{ flex: 1 }}>
          <label>Due On</label>
          <input
            type="date"
            className="form-control"
            value={task.dueOn}
            onChange={handleDueOnChange}
            style={{ height: "40px" }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 d-flex justify-content-end">
          <button type="submit" className="btn btn-dark">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
}

export default TaskForm;
