import {
  addContent,
  addTaskComment,
  completeTask,
  deleteTask,
  getContent,
  getSubContent,
  updateTask,
} from "../../services/ContentService";
import {
  ADD_CONTENT,
  ADD_SUB_CONTENT,
  EDIT_CONTENT,
  LOAD_CONTENTS,
  LOAD_SUB_CONTENTS,
  REMOVE_CONTENT,
} from "../actionTypes";

export const doAddTask = (groupId, data) => async (dispatch) => {
  const response = await addContent(groupId, "task", data);
  dispatch({
    type: ADD_CONTENT,
    payload: {
      groupId,
      item: response.data,
      type: "task",
    },
  });
};

export const doAddTaskComment = (groupId, taskId, data) => async (dispatch) => {
  const response = await addTaskComment(groupId, taskId, data);
  dispatch({
    type: ADD_SUB_CONTENT,
    payload: {
      groupId,
      type: "task",
      contentId: taskId,
      subtype: "comment",
      item: response.data,
    },
  });
};

export const fetchTasks = (groupId) => async (dispatch) => {
  const tasks = await getContent(groupId, "task");
  dispatch({
    type: LOAD_CONTENTS,
    payload: { groupId: groupId, items: tasks, type: "task" },
  });
};

export const fetchTaskComments = (groupId, taskId) => async (dispatch) => {
  const comments = await getSubContent(groupId, "task", taskId, "comment");
  dispatch({
    type: LOAD_SUB_CONTENTS,
    payload: {
      groupId: groupId,
      items: comments,
      type: "task",
      contentId: taskId,
      subtype: "comment",
    },
  });
};

export const doCompleteTask = (groupId, taskId) => (dispatch) => {
  completeTask(groupId, taskId, () => {
    dispatch({
      type: REMOVE_CONTENT,
      payload: { groupId, itemId: taskId, type: "task" },
    });
  });
};

export const doDeleteTask = (groupId, taskId) => async (dispatch) => {
  const success = await deleteTask(groupId, taskId);
  //task show error message when success is false
  dispatch({
    type: REMOVE_CONTENT,
    payload: { groupId, itemId: taskId, type: "task" },
  });
};

export const doEditTask = (groupId, taskId, task) => async (dispatch) => {
  const response = await updateTask(groupId, taskId, task);
  if (response.success) {
    dispatch({
      type: EDIT_CONTENT,
      payload: {
        groupId,
        itemId: taskId,
        type: "task",
        content: response.data,
      },
    });
  } else {
    //todo show toaster
  }
};

export const moveTaskToTop = (groupId, index) => (dispatch, getState) => {
  let { contents } = getState().group;
  let tasks = contents[groupId];
  let task = tasks[index];
  updateTask(groupId, task._id, { text: task.text });

  tasks.splice(index, 1);
  dispatch({
    type: LOAD_CONTENTS,
    payload: { groupId: groupId, items: [task, ...tasks], replace: true },
  });
};
