import Ajax from "./Ajax";
import { uploadFiles } from "./FileService";

export async function getContent(groupId, type, contentId) {
  let api = `/api/v1/content/${type}${contentId ? "/" + contentId : ""}?groupId=${groupId}`;

  return Ajax.get(api).then(function (json) {
    return json.data;
  });
}

export async function getSubContent(groupId, type, contentId, subtype) {
  let api = `/api/v1/content/${type}/${contentId}/${subtype}?groupId=${groupId}`;

  return Ajax.get(api).then(function (json) {
    return json.data;
  });
}

export function addContent(groupId, type, data) {
  return Ajax.post(`/api/v1/content/${type}?groupId=${groupId}`, data);
}

export function addBookmark(groupId, data) {
  return Ajax.post("/api/v1/content/bookmark?groupId=" + groupId, data);
}

export function addText(groupId, data) {
  return Ajax.post("/api/v1/content/text?groupId=" + groupId, data);
}

export function addBoard(groupId, data) {
  return Ajax.post("/api/v1/content/kanban?groupId=" + groupId, data);
}

export function addDrawing(groupId, data) {
  return Ajax.post("/api/v1/content/drawing?groupId=" + groupId, data);
}
export function addCode(groupId, data) {
  return Ajax.post("/api/v1/content/code?groupId=" + groupId, data);
}

export function addEvent(groupId, data) {
  return Ajax.post("/api/v1/content/event?groupId=" + groupId, data).then(
    (response) => {
      let event = response.data;
      event.start = new Date(event.start);
      event.end = new Date(event.end);
      return event;
    },
  );
}

export function updateDoc(groupId, docId, data) {
  return Ajax.patch(
    "/api/v1/content/doc/" + docId + "?groupId=" + groupId,
    data,
  );
}

export function addTaskComment(groupId, taskId, data) {
  return Ajax.post(
    `/api/v1/content/task/${taskId}/comment?groupId=${groupId}`,
    data,
  );
}

export function updateTaskComment(groupId, taskId, commentId, data) {
  return Ajax.patch(
    `/api/v1/content/task/${taskId}/comment/${commentId}?groupId=${groupId}`,
    data,
  );
}

export function deleteTaskComment(groupId, taskId, commentId) {
  return Ajax.delete(
    `/api/v1/content/task/${taskId}/comment/${commentId}?groupId=${groupId}`,
  );
}

export function deleteTask(groupId, cid) {
  return Ajax.delete(
    "/api/v1/content/task/" + cid + "?groupId=" + groupId,
  ).then(function (json) {
    return json.success;
  });
}

export function updateTask(groupId, taskId, task) {
  return Ajax.patch(
    "/api/v1/content/task/" + taskId + "?groupId=" + groupId,
    task,
  );
}

export function updateTextField(groupId, textFieldId, data) {
  return Ajax.patch(
    "/api/v1/content/text/" + textFieldId + "?groupId=" + groupId,
    data,
  );
}

export function deleteBookmark(groupId, cid) {
  return Ajax.delete("/api/v1/content/bookmark/" + cid + "?groupId=" + groupId);
}

export function deleteText(groupId, cid) {
  return Ajax.delete("/api/v1/content/text/" + cid + "?groupId=" + groupId);
}

export function deleteDoc(groupId, cid) {
  return Ajax.delete("/api/v1/content/doc/" + cid + "?groupId=" + groupId);
}

export function updateBoard(groupId, boardId, data) {
  return Ajax.patch(
    "/api/v1/content/kanban/" + boardId + "?groupId=" + groupId,
    data,
  );
}

export function deleteBoard(groupId, cid) {
  return Ajax.delete("/api/v1/content/kanban/" + cid + "?groupId=" + groupId);
}

export async function getEvents(groupId) {
  let api = "/api/v1/content/event?groupId=" + groupId;

  return Ajax.get(api).then(function (json) {
    let events = json.data;
    events.forEach((event) => {
      event.start = new Date(event.start);
      event.end = new Date(event.end);
    });
    return events;
  });
}

export function updateEvent(groupId, eventId, data) {
  return Ajax.patch(
    "/api/v1/content/event/" + eventId + "?groupId=" + groupId,
    data,
  ).then((response) => {
    let event = response.data;
    event.start = new Date(event.start);
    event.end = new Date(event.end);
    return event;
  });
}

export function addPostComment(groupId, postId, data) {
  return Ajax.post(
    `/api/v1/content/media-post/${postId}/comment?groupId=${groupId}`,
    data,
  );
}

export function updatePostComment(groupId, postId, commentId, data) {
  return Ajax.patch(
    `/api/v1/content/media-post/${postId}/comment/${commentId}?groupId=${groupId}`,
    data,
  );
}

export function deletePostComment(groupId, postId, commentId) {
  return Ajax.delete(
    `/api/v1/content/media-post/${postId}/comment/${commentId}?groupId=${groupId}`,
  );
}

export function addPostReaction(groupId, postId, data) {
  return Ajax.post(
    `/api/v1/content/media-post/${postId}/reaction?groupId=${groupId}`,
    data,
  );
}

export function deletePostReaction(groupId, postId, reactionId) {
  return Ajax.delete(
    `/api/v1/content/media-post/${postId}/reaction/${reactionId}?groupId=${groupId}`,
  );
}

export function updatePost(groupId, postId, data) {
  return Ajax.patch(
    "/api/v1/content/media-post/" + postId + "?groupId=" + groupId,
    data,
  );
}

export function deletePost(groupId, postId) {
  return Ajax.delete(
    "/api/v1/content/media-post/" + postId + "?groupId=" + groupId,
  );
}

export function getClosedContent(groupId, type) {
  let api = "/api/v1/content/" + type + "?groupId=" + groupId + "&closed=true";

  return Ajax.get(api).then(function (json) {
    return json.data;
  });
}

export function completeTask(groupId, cid, cb) {
  Ajax.delete(
    "/api/v1/content/task/" + cid + "?groupId=" + groupId + "&status=completed",
  ).then(function (json) {
    cb(json.success);
  });
}

export function reopenContent(groupId, cid, cb) {
  Ajax.put("/api/v1/content/task/" + cid + "/reopen?groupId=" + groupId).then(
    function (json) {
      cb(json.success);
    },
  );
}

export function updateDrawing(groupId, id, data) {
  return Ajax.patch(
    "/api/v1/content/drawing/" + id + "?groupId=" + groupId,

    data,
  );
}

export function deleteDrawing(groupId, cid) {
  return Ajax.delete("/api/v1/content/drawing/" + cid + "?groupId=" + groupId);
}

export function updateCode(groupId, id, data) {
  return Ajax.patch(
    "/api/v1/content/code/" + id + "?groupId=" + groupId,

    data,
  );
}

export function deleteCode(groupId, cid) {
  return Ajax.delete("/api/v1/content/code/" + cid + "?groupId=" + groupId);
}

export function updateQuestion(groupId, questionId, data) {
  return Ajax.patch(
    "/api/v1/content/question/" + questionId + "?groupId=" + groupId,

    data,
  );
}

export function deleteQuestion(groupId, questionId) {
  return Ajax.delete(
    "/api/v1/content/question/" + questionId + "?groupId=" + groupId,
  );
}

export async function addChatMessage(groupId, data) {
  if (data.files) {
    let response = await uploadFiles(groupId, data.files);
    if (response.success) {
      let uploaded = response.data;
      delete data.files;
      data.files = uploaded.map((file) => file._id);
    } else {
      throw new Error("File upload failed");
    }
  }

  return Ajax.post("/api/v1/content/chat?groupId=" + groupId, data);
}

export function updateChatMessage(groupId, messageId, data) {
  return Ajax.patch(
    "/api/v1/content/chat/" + messageId + "?groupId=" + groupId,
    data,
  );
}

export function deleteChatMessage(groupId, messageId) {
  return Ajax.delete(
    "/api/v1/content/chat/" + messageId + "?groupId=" + groupId,
  );
}

export function addAnswer(groupId, questionId, data) {
  return Ajax.post(
    `/api/v1/content/question/${questionId}/answer?groupId=${groupId}`,
    data,
  );
}

export function deleteAnswer(groupId, questionId, answerId) {
  return Ajax.delete(
    `/api/v1/content/question/${questionId}/answer/${answerId}?groupId=${groupId}`,
  );
}

export function updateAnswer(groupId, questionId, answerId, data) {
  return Ajax.patch(
    `/api/v1/content/question/${questionId}/answer/${answerId}?groupId=${groupId}`,
    data,
  );
}

export async function getFeeds() {
  let api = "/api/v1/feeds";

  return Ajax.get(api).then(function (json) {
    return json.data;
  });
}
