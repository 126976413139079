import React, { useState } from "react";
import TextEditor from "../docs/Editor/TextEditor";

function AnswerEditor({ answer = {}, submit, cancel, edit }) {
  const [content, setContent] = useState(answer ? answer.content : "");

  const onChange = (content) => {
    setContent(content);
  };

  const submitAnswer = () => {
    if (!content) {
      return;
    }
    submit({ ...answer, content });
    setContent("");
  };

  return (
    <div>
      <div>
        <TextEditor
          content={content}
          setContent={onChange}
          className="micro"
          placeholder="Type your answer here"
          minimal
          noInitialFocus
        />
      </div>
      <div className="d-flex align-items-center justify-content-end mt-2">
        {edit && (
          <a className="btn btn-default btn-sm mb-0 mr-2" onClick={cancel}>
            Cancel
          </a>
        )}
        <button
          type="submit"
          className="btn btn-sm btn-dark mb-0"
          onClick={submitAnswer}
        >
          {edit ? "Update" : "Add"}
        </button>
      </div>
    </div>
  );
}

export default AnswerEditor;
