import {
  addContent,
  addPostComment,
  addPostReaction,
  deletePost,
  deletePostComment,
  deletePostReaction,
  getContent,
  getSubContent,
  removePostComment,
  updatePost, updatePostComment
} from "../../services/ContentService";
import {
  ADD_CONTENT,
  ADD_SUB_CONTENT,
  EDIT_CONTENT,
  EDIT_SUB_CONTENT,
  LOAD_CONTENTS,
  LOAD_SUB_CONTENTS,
  REMOVE_CONTENT,
  REMOVE_SUB_CONTENT,
} from "../actionTypes";

export const doAddPost = (groupId, data) => async (dispatch) => {
  const response = await addContent(groupId, "media-post", data);
  dispatch({
    type: ADD_CONTENT,
    payload: {
      groupId,
      item: response.data,
      type: "media-post",
    },
  });
};

export const doAddPostComment = (groupId, postId, data) => async (dispatch) => {
  const response = await addPostComment(groupId, postId, data);
  dispatch({
    type: ADD_SUB_CONTENT,
    payload: {
      groupId,
      type: "media-post",
      contentId: postId,
      subtype: "comment",
      item: response.data,
    },
  });
};

export const doUpdatePostComment =
  (groupId, postId, commentId, data) => async (dispatch) => {
    const response = await updatePostComment(groupId, postId, commentId, data);
    dispatch({
      type: EDIT_SUB_CONTENT,
      payload: {
        groupId,
        contentId: postId,
        type: "media-post",
        subtype: "comment",
        subContentId: commentId,
        item: response.data,
      },
    });
  };

export const doDeletePostComment =
  (groupId, postId, commentId) => async (dispatch) => {
    const response = await deletePostComment(groupId, postId, commentId);
    dispatch({
      type: REMOVE_SUB_CONTENT,
      payload: {
        groupId,
        contentId: postId,
        type: "media-post",
        subtype: "comment",
        subContentId: commentId,
      },
    });
  };

export const doAddPostReaction =
  (groupId, postId, data) => async (dispatch) => {
    const response = await addPostReaction(groupId, postId, data);
    dispatch({
      type: ADD_SUB_CONTENT,
      payload: {
        groupId,
        type: "media-post",
        contentId: postId,
        subtype: "reaction",
        item: response.data,
      },
    });
  };

export const doFetchPostReactions = (groupId, postId) => async (dispatch) => {
  const reactions = await getSubContent(
    groupId,
    "media-post",
    postId,
    "reaction",
  );
  dispatch({
    type: LOAD_SUB_CONTENTS,
    payload: {
      groupId: groupId,
      items: reactions,
      type: "media-post",
      contentId: postId,
      subtype: "reaction",
    },
  });
};

export const doRemovePostReaction =
  (groupId, postId, reactionId) => async (dispatch) => {
    const response = await deletePostReaction(groupId, postId, reactionId);
    dispatch({
      type: REMOVE_SUB_CONTENT,
      payload: {
        groupId,
        type: "media-post",
        contentId: postId,
        subtype: "reaction",
        subContentId: reactionId,
      },
    });
  };

export const doUpdatePost = (groupId, postId, data) => async (dispatch) => {
  const response = await updatePost(groupId, postId, data);
  dispatch({
    type: EDIT_CONTENT,
    payload: {
      groupId,
      itemId: postId,
      type: "media-post",
      content: response.data,
    },
  });
};

export const fetchPosts = (groupId) => async (dispatch) => {
  const posts = await getContent(groupId, "media-post");
  dispatch({
    type: LOAD_CONTENTS,
    payload: { groupId, items: posts, type: "media-post" },
  });
};

export const fetchPostComments = (groupId, postId) => async (dispatch) => {
  const comments = await getSubContent(
    groupId,
    "media-post",
    postId,
    "comment",
  );
  dispatch({
    type: LOAD_SUB_CONTENTS,
    payload: {
      groupId: groupId,
      items: comments,
      type: "media-post",
      contentId: postId,
      subtype: "comment",
    },
  });
};

export const doDeletePost = (groupId, postId) => async (dispatch) => {
  const response = await deletePost(groupId, postId);
  dispatch({
    type: REMOVE_CONTENT,
    payload: { groupId, itemId: postId, type: "media-post" },
  });
};
