import React from "react";
import RichTextView from "./Editor/RichTextView";

export default ({ doc, preview }) => {
  return (
    <div className="notes-content" style={{ borderTop: 0, paddingTop: 0 }}>
      {preview ? (
        <div
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {doc.content.replace(/<[^>]+>/g, " ")}
        </div>
      ) : (
        <RichTextView content={doc.content} />
      )}
    </div>
  );
};
