import React, { Component, useEffect } from "react";
import { matchRoutes, Outlet, useLocation } from "react-router-dom";
import { getGroups } from "../../services/GroupService";
import { navigateToPage } from "../../state/actions/ui";
import { connect } from "react-redux";
import SideBar from "../layout/SideBar";
import GroupSideBar from "../layout/GroupSideBar";

function WorkHome(props) {
  const location = useLocation();

  useEffect(() => {
    props.navigateToPage("GROUPS");
  }, []);

  const routes = [
    { path: "/home/groups/:groupId" },
    { path: "/home/groups/:groupId/posts" },
    { path: "/home/groups/:groupId/tasks" },
    { path: "/home/groups/:groupId/docs" },
    { path: "/home/groups/:groupId/questions" },
    { path: "/home/groups/:groupId/files" },
    { path: "/home/groups/:groupId/calendar" },
  ];
  const matched = matchRoutes(routes, location);
  let path = matched ? matched[0].route.path : null;

  return (
    <div
      className="row no-gutters d-flex justify-content-center"
      style={{ height: "calc(100vh - 60px)" }}
    >
      <div
        className="col-md-2 pt-3"
        style={{
          background: "#f8f8f8",
          borderRight: "1px solid #e2e2e2",
        }}
      >
        <SideBar />
      </div>

      <div
        className="col-md-10 pt-3"
        style={{ height: "calc(100vh - 60px)", overflow: "auto" }}
      >
        <Outlet />
      </div>
    </div>
  );
}

const actions = {
  navigateToPage,
};

export default connect(null, actions)(WorkHome);
