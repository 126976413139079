import React from "react";
import TaskRow from "../content/tasks/TaskRow";
import FileViewInline from "../content/files/FileViewInline";
import PostContent from "../content/MediaPosts/PostContent";
import DocContent from "../content/docs/DocContent";
import QuestionContent from "../content/question/QuestionContent";

const FeedContent = ({ feed, openFile, openTask }) => {
  const { type } = feed;
  if (type === "media-post") {
    return <PostContent groupId={feed.group} post={feed} />;
  }

  if (type === "task") {
    return <TaskRow task={feed} startEdit={openTask} />;
  }

  if (type === "doc") {
    return <DocContent doc={feed} />;
  }

  if (type === "question") {
    return <QuestionContent question={feed} groupId={feed.group} />;
  }

  if (type === "files") {
    return (
      <FileViewInline file={feed} embedded openFile={() => openFile(feed)} />
    );
  }

  return null;
};

export default FeedContent;
