import React, { Component } from "react";
import { doAddDoc, doUpdateDoc } from "../../../state/actions/docs";
import { connect } from "react-redux";
import TextEditor from "./Editor/TextEditor";

class DocEditor extends Component {
  constructor(props) {
    super(props);

    const { content, title } = this.props.doc || {};
    this.state = { content, title };
  }

  onChangeContent = (content) => {
    this.setState({ content });
  };

  onChangeTitle = (e) => {
    this.setState({ title: e.target.value });
  };

  addDoc = () => {
    const { title, content } = this.state;
    let data = { title, content };
    let { addDoc, onDocAddUpdate, groupId } = this.props;
    data.groupId = groupId;
    addDoc(groupId, data);
    onDocAddUpdate();
  };

  updateDoc = () => {
    const { title, content } = this.state;
    let data = { title, content };
    let { updateDoc, onDocAddUpdate, groupId, docId } = this.props;
    updateDoc(groupId, docId, data);
    onDocAddUpdate();
  };

  render() {
    let { newContent, style, groupId } = this.props;
    let { content, title } = this.state;
    return (
      <div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            value={title}
            onChange={this.onChangeTitle}
            style={{ height: "40px" }}
            placeholder="Title"
          />
        </div>
        <div style={style ? style : {}}>
          <TextEditor
            groupId={groupId}
            content={content}
            setContent={this.onChangeContent}
          />
        </div>
        <hr />
        <div className="d-flex align-items-center justify-content-between m-t-md">
          <button
            style={{ width: 100, height: 39 }}
            className="btn btn-default btn-sm mb-0 mr-1"
            onClick={(e) => {
              e.preventDefault();
              this.props.toggleDocs();
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-dark mb-0"
            onClick={newContent ? this.addDoc : this.updateDoc}
          >
            {newContent ? "Add" : "Update"}
          </button>
        </div>
      </div>
    );
  }
}

const actions = {
  addDoc: doAddDoc,
  updateDoc: doUpdateDoc,
};

export default connect(null, actions)(DocEditor);
