import React, { Component } from "react";
import { signUp } from "../../services/DataService";
import Error from "./../alerts/Error";
import { Link } from "react-router-dom";

export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submit = e => {
    e.preventDefault();
    this.setState({
      error: ""
    });
    signUp(this.state, response => {
      if (response.success) {
        this.props.history.push("/signup/confirmation");
        this.setState({
          name: "",
          email: "",
          password: "",
          confirmPassword: ""
        });
      } else {
        this.setState({ error: response.message });
      }
    });
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  handlePasswordChange = e => {
    this.setState({ password: e.target.value });
  };

  handleConfirmPasswordChange = e => {
    this.setState({ confirmPassword: e.target.value });
  };

  render() {
    var inputStyle = { borderWidth: "1px", height: "40px" };

    return (
      <div className="loginColumns animated fadeInDown">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="text-center">
              <img src="/img/logo/logo.png" style={{ height: 42 }} />
            </div>

            <div
              className="ibox-content"
              style={{ padding: "30px", marginTop: "30px", borderRadius: 4 }}
            >
              <h4 className="font-bold text-center">Sign Up</h4>
              <Error>{this.state.error}</Error>

              <form className="m-t" noValidate onSubmit={this.submit}>
                <div className="form-group">
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Name"
                    value={this.state.name}
                    onChange={this.handleNameChange}
                    style={inputStyle}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    style={inputStyle}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    style={inputStyle}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm Password"
                    value={this.state.confirmPassword}
                    onChange={this.handleConfirmPasswordChange}
                    style={inputStyle}
                  />
                </div>
                <button
                  className="btn btn-primary block full-width m-b"
                  type="submit"
                >
                  <strong>Sign Up</strong>
                </button>
                <p className="text-muted text-center" />
                <Link
                  className="btn btn-sm btn-white btn-block full-width"
                  style={{ marginTop: "30px" }}
                  to="/signin"
                >
                  Existing User? Sign In
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
