import React, { useEffect, useState } from "react";
import { getFiles, getFile } from "../../../services/FileService";
import FilesView from "../files/FilesView";

function ChatFilesContainer(props) {
  const [files, setFiles] = useState();

  useEffect(() => {
    let { group } = props;
    loadFiles(group._id);
  }, [props.group]);

  const loadFiles = async (groupId) => {
    const files = await getFiles(groupId);
    setFiles(files);
  };

  const openFile = (fileId) => {
    let { group } = props;
    return getFile(group._id, fileId);
  };

  return (
    <FilesView
      {...props}
      files={files}
      openFile={openFile}
    />
  );
}

export default ChatFilesContainer;
