import React, { useEffect, useState } from "react";
import CalendarView from "./CalendarView";
import {
  getContent,
  addEvent,
  updateEvent,
  getEvents,
} from "../../../services/ContentService";
import { useParams } from "react-router-dom";

function CalendarContainer() {
  const { groupId } = useParams();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    const events = await getEvents(groupId, null, "event");
    setEvents(events);
  };

  const addEvent = async (data) => {
    let event = await addEvent(groupId, data);
    if (event) {
      events.push(event);
      setEvents([...events]);
    }
  };

  const updateEvent = async (data) => {
    let event = await updateEvent(groupId, null, data._id, data);
    let index = events.findIndex((e) => e._id === data._id);
    events[index] = { ...event };
    setEvents([...events]);
  };

  return (
    <CalendarView
      events={events}
      addEvent={addEvent}
      updateEvent={updateEvent}
    />
  );
}

export default CalendarContainer;
