import AddIcon from "../icons/AddIcon";
import React, { useState } from "react";
import GroupLink from "./GroupLink";
import ModalPopup from "../modal/ModalPopup";
import AddGroup from "./AddGroup";

const GroupsView = ({ groups = [], refreshGroups }) => {
  const [showNewGroupModal, setShowNewGroupModal] = useState(false);

  const toggleNewGroupModal = () => {
    setShowNewGroupModal(!showNewGroupModal);
  };

  const groupNodes = groups.map((group) => {
    return (
      <div className="col-md-12" key={group._id}>
        <GroupLink group={group} refreshGroups={refreshGroups} />
      </div>
    );
  });

  return (
    <div className="d-flex justify-content-center">
      <div className="col-md-8">
        <div
          className="ibox ibox-content"
          style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
        >
          {groups.length === 0 ? (
            <div className="my-4 text-center" style={{ fontSize: 16 }}>
              You are not part of any groups at the moment. Click{" "}
              <a onClick={toggleNewGroupModal} className="link">
                here
              </a>{" "}
              to create one.
            </div>
          ) : (
            <div className="d-flex align-items-center  justify-content-between mb-3">
              <div>
                <h3 className="text-center">{`Groups - ${groups.length}`}</h3>
              </div>
              <div className="ml-3">
                <button
                  className="btn btn-dark btn-w-icon btn-sm"
                  onClick={toggleNewGroupModal}
                >
                  <AddIcon className="mr-2" />
                  New Group
                </button>
              </div>
            </div>
          )}
          <div className="chat-activity-list row">{groupNodes}</div>

          {showNewGroupModal && (
            <ModalPopup show handleClose={toggleNewGroupModal}>
              <AddGroup
                onAddGroup={() => {
                  refreshGroups();
                  toggleNewGroupModal();
                }}
              />
            </ModalPopup>
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupsView;
