import { useState } from "react";
import DocEditor from "./DocEditor";
import ModalPopup from "../../modal/ModalPopup";
import DocView from "./DocView";
import DateTime from "../../widgets/DateTime";

function DocsView({ groupId, docs = [] }) {
  const [addDoc, setAddDoc] = useState(false);
  const [docIndexToView, setDocIndexToView] = useState(-1);
  const [edit, setEdit] = useState(false);

  const toggleDocs = () => {
    setAddDoc(!addDoc);
  };

  const cancelEdit = () => {
    setDocIndexToView(-1);
    setEdit(false);
  };

  const onDocAddUpdate = () => {
    setAddDoc(false);
    cancelEdit();
  };

  return (
    <div className="col-md-8 offset-md-2">
      <div className={`ibox float-e-margins`}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>Docs</h3>

          <div>
            <button
              className="btn btn-dark btn-sm"
              onClick={(e) => {
                e.preventDefault();
                toggleDocs();
              }}
            >
              New Doc
            </button>
          </div>
        </div>

        <div
          className={`d-flex flex-wrap ${docs.length >= 3 ? "justify-content-between" : ""}`}
        >
          {docs.length === 0 && (
            <div className="text-center">There are no docs at the moment.</div>
          )}

          {docs.map((doc, index) => {
            return (
              <div className="col-md-4 mb-3" key={doc._id}>
                <div
                  className="card mr-2"
                  style={{
                    borderTopRightRadius: 4,
                    borderTopLeftRadius: 4,
                    cursor: "pointer",
                  }}
                  key={doc._id}
                  onClick={() => {
                    setDocIndexToView(index);
                  }}
                >
                  <div className="card-body text-center">
                    <h3>{doc.title}</h3>

                    <div className="my-3">
                      <small className=" dfjbac text-muted">
                        <span>{doc.user.name}</span>
                        <span className="mx-1"></span>
                        <DateTime value={new Date(doc.dateCreated)} />
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {docIndexToView > -1 && (
          <ModalPopup
            show={true}
            centered={true}
            fullscreen
            handleClose={cancelEdit}
            title={edit ? "Edit Document" : ""}
          >
            <div className="row justify-content-center align-items-center">
              <div className="col-sm-12 col-md-8 col-md-offset-2">
                {edit ? (
                  <DocEditor
                    groupId={groupId}
                    docId={docs[docIndexToView]._id}
                    doc={docs[docIndexToView]}
                    onDocAddUpdate={onDocAddUpdate}
                    toggleDocs={onDocAddUpdate}
                  />
                ) : (
                  <DocView
                    groupId={groupId}
                    doc={docs[docIndexToView]}
                    startEdit={() => {
                      setEdit(true);
                    }}
                    onDocDelete={cancelEdit}
                  />
                )}
              </div>
            </div>
          </ModalPopup>
        )}

        {addDoc && (
          <ModalPopup
            show={true}
            centered={true}
            // noBg={true}
            fullscreen
            handleClose={toggleDocs}
            title="New Document"
          >
            <div className="row justify-content-center align-items-center">
              <div className="col-sm-12 col-md-8 col-md-offset-2">
                <DocEditor
                  groupId={groupId}
                  onDocAddUpdate={onDocAddUpdate}
                  toggleDocs={toggleDocs}
                  newContent
                />
              </div>
            </div>
          </ModalPopup>
        )}
      </div>
    </div>
  );
}

export default DocsView;
