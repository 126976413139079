import Avatar from "../widgets/Avatar";
import { getNameInitials } from "../../utils/Utils";
import DateTime from "../widgets/DateTime";
import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../icons/Icon";

const getTypeLabel = (feed) => {
  let { type, title } = feed;
  if (type === "task") {
    return <span>Task</span>;
  } else if (type === "doc") {
    return "Document " + title;
  } else if (type === "files") {
    return "File";
  } else if (type === "question") {
    return "Question";
  } else if (type === "media-post") {
    return "Post";
  } else if (type === "calendar") {
    return "Calendar Event";
  }
};

function getLinkPart(type) {
  if (type === "media-post") {
    return "";
  }

  if (type === "files") {
    return "/files";
  }
  return `/${type}s`;
}

const FeedTitle = ({ feed, groupName }) => {
  const navigate = useNavigate();
  const user = feed.user || feed.createdBy;
  const createdOn = feed.createdOn || feed.dateCreated;
  return (
    <div className="dfjbac mb-2">
      <div className="dfac">
        <Avatar text={getNameInitials(user.name)} className="mr-2" />
        <div style={{ fontSize: "16px", fontWeight: "500" }}>{user.name}</div>
        <div className="mx-1">
          added a <span style={{ fontWeight: 500 }}>{getTypeLabel(feed)}</span>{" "}
          in{" "}
        </div>
        <div
          className="badge"
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/home/groups/${feed.group}${getLinkPart(feed.type)}`)
          }
        >
          {groupName.toUpperCase()}
        </div>
      </div>
      <div className="text-muted small">
        <DateTime value={new Date(createdOn)} />
      </div>
    </div>
  );
};

export default FeedTitle;
