import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { doAddPost, doUpdatePost } from "../../../state/actions/posts";
import { uploadFiles } from "../../../services/FileService";
import TextEditor from "../docs/Editor/TextEditor";

function PostEditor({ groupId, post = {}, submit, newContent }) {
  const [content, setContent] = useState(post.content || "");
  const [files, setFiles] = useState([]);
  const [filesUploading, setFilesUploading] = useState(false);

  const onChangeContent = (content) => {
    setContent(content);
  };

  const handleFilesChange = (e) => {
    const files = [];
    const nonArrayFiles = e.target.files;
    for (let i = 0; i < nonArrayFiles.length; i++) {
      files.push(nonArrayFiles[i]);
    }
    setFiles(files);
  };

  const onSubmitPost = async () => {
    let data = { content };
    let uploaded = [];
    if (files.length >= 1) {
      uploaded = await upload();
      data.files = uploaded;
    }
    submit(data);
  };

  const upload = () => {
    setFilesUploading(true);
    return uploadFiles(groupId, files)
      .then((res) => {
        setFiles([]);
        setFilesUploading(false);
        return res.data;
      })
      .catch((e) => {
        setFilesUploading(false);
      });
  };

  return (
    <div>
      <div className="form-group">
        <TextEditor
          content={content}
          setContent={onChangeContent}
          placeholder="Type post content here..."
          className="post"
          minimal
        />
      </div>

      <div className="file-manager mt-4">
        <button
          className="btn btn-rounded btn-dark btn-sm"
          style={{ borderRadius: "100%", padding: "5px 8px" }}
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-photo"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1="15" y1="8" x2="15.01" y2="8"></line>
            <rect x="4" y="4" width="16" height="16" rx="3"></rect>
            <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5"></path>
            <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2"></path>
          </svg>
          <span className="MuiTouchRipple-root css-w0pj6f"></span>
        </button>
        <label className="ml-2" tabIndex="0" role="button">
          <input
            hidden
            accept="image/*"
            multiple="true"
            type="file"
            onChange={handleFilesChange}
          />
          Photo / Video
          <span className="ml-2">
            {files.map((f, index) => f.name).join(", ")}
          </span>
        </label>
      </div>

      <div className="d-flex align-items-center justify-content-end m-t-md">
        <button type="submit" className="btn btn-dark" onClick={onSubmitPost}>
          {newContent ? "Post" : "Update"}
        </button>
      </div>
    </div>
  );
}

export default PostEditor;
