import {
  addAnswer,
  addContent,
  addPostComment,
  addQuestion,
  deleteAnswer,
  deleteQuestion,
  getContent,
  getSubContent,
  updateAnswer,
  updateQuestion,
} from "../../services/ContentService";
import {
  ADD_CONTENT,
  ADD_SUB_CONTENT,
  EDIT_CONTENT,
  EDIT_SUB_CONTENT,
  LOAD_CONTENTS,
  LOAD_SUB_CONTENTS,
  REMOVE_CONTENT,
  REMOVE_SUB_CONTENT,
} from "../actionTypes";

export const doAddQuestion = (groupId, data) => async (dispatch) => {
  const response = await addContent(groupId, "question", data);
  dispatch({
    type: ADD_CONTENT,
    payload: {
      groupId,
      item: response.data,
      type: "question",
    },
  });
};

export const doUpdateQuestion =
  (groupId, questionId, data) => async (dispatch) => {
    const response = await updateQuestion(groupId, questionId, data);
    dispatch({
      type: EDIT_CONTENT,
      payload: {
        groupId,
        itemId: questionId,
        type: "question",
        content: response.data,
      },
    });
  };

export const fetchQuestions = (groupId) => async (dispatch) => {
  const questions = await getContent(groupId, "question");
  dispatch({
    type: LOAD_CONTENTS,
    payload: { groupId, items: questions, type: "question" },
  });
};

export const doDeleteQuestion = (groupId, questionId) => async (dispatch) => {
  const response = await deleteQuestion(groupId, questionId);
  dispatch({
    type: REMOVE_CONTENT,
    payload: { groupId, itemId: questionId, type: "question" },
  });
};

export const fetchAnswers = (groupId, questionId) => async (dispatch) => {
  const answers = await getSubContent(
    groupId,
    "question",
    questionId,
    "answer",
  );
  dispatch({
    type: LOAD_SUB_CONTENTS,
    payload: {
      groupId: groupId,
      items: answers,
      type: "question",
      contentId: questionId,
      subtype: "answer",
    },
  });
};

export const doAddAnswer = (groupId, questionId, data) => async (dispatch) => {
  const response = await addAnswer(groupId, questionId, data);
  dispatch({
    type: ADD_SUB_CONTENT,
    payload: {
      groupId,
      type: "question",
      contentId: questionId,
      subtype: "answer",
      item: response.data,
    },
  });
};

export const doDeleteAnswer =
  (groupId, questionId, answerId) => async (dispatch) => {
    await deleteAnswer(groupId, questionId, answerId);
    dispatch({
      type: REMOVE_SUB_CONTENT,
      payload: {
        groupId,
        type: "question",
        contentId: questionId,
        subtype: "answer",
        subContentId: answerId,
      },
    });
  };

export const doUpdateAnswer =
  (groupId, questionId, answerId, data) => async (dispatch) => {
    const response = await updateAnswer(groupId, questionId, answerId, data);
    dispatch({
      type: EDIT_SUB_CONTENT,
      payload: {
        groupId,
        contentId: questionId,
        type: "question",
        subtype: "answer",
        subContentId: answerId,
        item: response.data,
      },
    });
  };
