import { getGroup, updateGroupUserUi } from "../../services/GroupService";
import {
  addBookmark,
  addEvent,
  addDoc,
  addText,
  addTask,
  deleteBookmark,
  deleteDoc,
  deleteText,
  deleteTask,
  updateBoard,
} from "../../services/ContentService";

import {
  SET_GROUP,
  CHANGE_GROUP_VIEW,
  SHOW_MOVE_OR_COPY,
  HIDE_MOVE_OR_COPY,
  ADD_CONTENT,
  REMOVE_CONTENT,
  DO_NOTHING,
  RESET_GROUP,
  TOGGLE_TYPE_VIEW,
  TOGGLE_CALENDAR_OPEN,
  TOGGLE_ACTIVITIES,
  TOGGLE_CHAT_OPEN,
  TOGGLE_ALL_TYPES_VIEW,
  SHOW_ALL_TYPES_VIEW,
} from "../actionTypes";
import { closeGroup, openGroup } from "../../services/Socket";

export const fetchGroup = (groupId) => {
  return async (dispatch, getState) => {
    dispatch({ type: RESET_GROUP });
    const group = await getGroup(groupId);
    dispatch({ type: SET_GROUP, payload: { group } });
    openGroup(groupId);
  };
};

export const closeCollection = (groupId) => {
  closeGroup(groupId);
  return resetGroup();
};

export const resetGroup = () => {
  return { type: RESET_GROUP };
};

export const changeView = (groupId, view) => async (dispatch, getState) => {
  const { group } = getState();
  const { groupData } = group;
  const { ui } = groupData;
  const response = await updateGroupUserUi(groupId, {
    ...ui,
    view,
  });

  dispatch({
    type: CHANGE_GROUP_VIEW,
    payload: {
      view,
    },
  });
};

export const moveOrCopy = (data) => async (dispatch, getState) => {
  return dispatch({
    type: SHOW_MOVE_OR_COPY,
    payload: {
      show: true,
      data,
    },
  });
};

export const doMoveOrCopy = (destGId, data) => async (dispatch) => {
  executeMoveOrCopy(dispatch, destGId, data, true);
};

function getTextForContent(data) {
  let { item, from } = data;
  if (from === "text" || from === "todo") {
    return item.text;
  }
  if (from === "bookmark") {
    return item.url;
  }
  if (from === "notes") {
    return item.content ? item.content.replace(/<[^>]+>/g, "") : "";
  }
}

async function executeMoveOrCopy(dispatch, destGId, data, closePop) {
  const { groupId, item, from, to, action } = data;

  let response = null;
  let text = getTextForContent(data);
  if (!text) {
    dispatch({
      type: DO_NOTHING,
    });
    return;
  }

  // if (to === "task") {
  //   response = await addTask(groupId, destGId, {
  //     text: getTextForContent(data),
  //   });
  // } else if (to === "text") {
  //   response = await addText(groupId, destGId, {
  //     text: getTextForContent(data),
  //   });
  // } else if (to === "doc") {
  //   response = await addDoc(groupId, destGId, {
  //     content: getTextForContent(data),
  //   });
  // } else if (to === "bookmark") {
  //   response = await addBookmark(groupId, destGId, {
  //     url: getTextForContent(data),
  //   });
  // } else if (to === "calendar") {
  //   response = await addEvent(groupId, destGId, {
  //     groupId: data.groupId,
  //     start: data.start,
  //     end: data.end,
  //   });
  // } else if (to === "kanban") {
  //   response = await updateBoard(groupId, destGId, data);
  // }
  // if (response) {
  //   dispatch({
  //     type: ADD_CONTENT,
  //     payload: {
  //       item: response.data,
  //     },
  //   });
  // } else {
  //   dispatch({
  //     type: DO_NOTHING,
  //   });
  //   return;
  // }
  //
  // if (action === "move") {
  //   if (from === "text") {
  //     await deleteText(groupId, item._id);
  //   } else if (from === "bookmark") {
  //     await deleteBookmark(groupId, item._id);
  //   } else if (from === "task") {
  //     await deleteTask(groupId, item._id);
  //   } else if (from === "doc") {
  //     await deleteDoc(groupId, item._id);
  //   }
  //   dispatch({
  //     type: REMOVE_CONTENT,
  //     payload: { itemId: item._id },
  //   });
  // }

  if (closePop) {
    dispatch({
      type: HIDE_MOVE_OR_COPY,
    });
  }
}

export const closeMoveOrCopy = () => {
  return {
    type: HIDE_MOVE_OR_COPY,
  };
};

export const toggleTypeView = (type) => {
  return {
    type: TOGGLE_TYPE_VIEW,
    payload: { type },
  };
};

export const toggleAllTypesView = (types) => {
  return {
    type: TOGGLE_ALL_TYPES_VIEW,
    payload: { types },
  };
};

export const showAllTypesView = () => {
  return {
    type: SHOW_ALL_TYPES_VIEW,
  };
};

export const toggleCalendar = () => {
  return {
    type: TOGGLE_CALENDAR_OPEN,
  };
};

export const toggleActivities = () => {
  return {
    type: TOGGLE_ACTIVITIES,
  };
};

export const toggleChat = () => {
  return {
    type: TOGGLE_CHAT_OPEN,
  };
};
