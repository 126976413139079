import React from "react";
import { resendEmailConfirmation } from "../../services/DataService";
import Error from "../alerts/Error";
import { connect } from "react-redux";
import { toastMessage } from "../../state/actions/toaster";

class ResendEmailConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: this.props.email };
  }

  submit = (e) => {
    e.preventDefault();
    this.setState({ error: "" });
    resendEmailConfirmation(this.state.email, (res) => {
      if (res.success) {
        this.props.onComplete();
        this.props.toastMessage(
          "Done! We have sent you an email with instructions on how to confirm your email.",
          "success",
          3
        );
      } else {
        var message =
          res.message ||
          "We are sorry! Resending email confirmation failed. Please try again later.";
        this.setState({ error: message });
        return;
      }
    });
  };

  render() {
    return (
      <div className="wp-block default user-form">
        <Error message={this.state.error} style={{ margin: "10px" }} />

        <div className="form-body">
          <form
            onSubmit={this.submit}
            name="resendEmailConfirmation"
            id="resendEmailConfirmation"
            className="sky-form"
            novalidate
          >
            <fieldset style={{ textAlign: "center" }}>
              <section className="col-md-6 col-md-offset-3">
                <div className="form-group">
                  <label className="label">Email</label>
                  <label className="input">
                    <input
                      type="text"
                      name="email"
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  </label>
                </div>
              </section>
              <section className="col-md-6 col-md-offset-3">
                <div className="form-group">
                  <label className="input">
                    <button className="btn btn-base btn-sign-in" type="submit">
                      <span>Send Email Confirmation</span>
                    </button>
                  </label>
                </div>
              </section>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

const actions = {
  toastMessage: toastMessage,
};

export default connect(null, actions)(ResendEmailConfirmation);
