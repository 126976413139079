import React, { Component } from "react";
import "./home.css";

export default class WhatIsIt extends Component {
  render() {
    return (
      <div>
        <section
          className="content-section py-4 text-white"
          // style={{ backgroundColor: "#2d85c6" }}
          // style={{ backgroundColor: "#6D90B9" }}
          style={{
            background:
              "linear-gradient(90deg, hsla(288, 20%, 75%, 1) 0%, hsla(197, 65%, 42%, 1) 100%)"
          }}
        >
          <div className="container my-4">
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-5 mb-lg-0 align-items-center d-flex align-items-center my-4">
                <div className="content-section-heading py-3 mb-3 ">
                  <div style={{ lineHeight: 2, fontSize: 20, fontWeight: 400 }}>
                    Everyday we generate, manage, consume, and share different
                    types of data related to our life and work. The context is
                    lost when we juggle between different apps and services.
                    <br />
                    <br />
                    Bundl fixes this for you!
                    {/*Possibilities are endless. You can use it in many different*/}
                    {/*ways. <a className="btn-link text-white">more...</a>*/}
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-6 text-center my-4">
                <div className="row">
                  <div className="col-lg-6 col-md-6 mb-5 mb-3">
                    <h3 style={{ fontSize: 18 }}>Features</h3>
                    <p className="text-faded mb-0 font-lg">
                      Create/Store different types of data together using one
                      sign in.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 mb-5 mb-3">
                    <h3 style={{ fontSize: 18 }}>Flexible</h3>
                    <p className="text-faded mb-0 font-lg">
                      Use only what you need
                    </p>
                  </div>

                  <div className="col-lg-6 col-md-6 mb-5 mb-3">
                    <h3 style={{ fontSize: 18 }}>Context</h3>
                    <p className="text-faded mb-0 font-lg">
                      Create context for your data and use them effectively.
                    </p>
                  </div>

                  <div className="col-lg-6 col-md-6 mb-5 mb-3">
                    <h3 style={{ fontSize: 18 }}>Collaborate</h3>
                    <p className="text-faded mb-0 font-lg">
                      Invite and work with your friends and co-workers
                    </p>
                  </div>

                  <div className="col-lg-6 col-md-6 mb-3">
                    <h3 style={{ fontSize: 18 }}>Integrations</h3>
                    <p className="text-faded mb-0 font-lg">
                      Integrate third party services that you already use
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 mb-3">
                    <h3 style={{ fontSize: 18 }}>East To Use!</h3>
                    <p className="text-faded mb-0 font-lg">
                      Get started and start using immediately.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
