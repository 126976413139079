import { addContent, getFeeds } from "../../services/ContentService";
import { uploadFiles } from "../../services/FileService";
import { ADD_FEED, LOAD_FEEDS, RESET_FEEDS } from "../actionTypes";

export const fetchFeeds = () => {
  return async (dispatch) => {
    dispatch({ type: RESET_FEEDS });
    const feeds = await getFeeds();
    dispatch({ type: LOAD_FEEDS, payload: { feeds } });
  };
};

export const addFeed =
  ({ type, groupId, title, content, files }) =>
  async (dispatch) => {
    const data = {};
    if (type === "task") {
      data.title = title;
      data.description = content;
    } else {
      data.content = content;
    }

    if (type === "media-post" && files && files.length >= 1) {
      data.files = await uploadFiles(groupId, files).then((res) => {
        return res.data;
      });
    }

    const response = await addContent(groupId, type, data);
    dispatch({
      type: ADD_FEED,
      payload: {
        feed: { ...response.data, type },
      },
    });

    // { value: "media-post", label: "Post" },
    // { value: "task", label: "Task" },
    // { value: "doc", label: "Doc" },
    // { value: "question", label: "Question" },
  };
