import { getUser } from "./DataService";
import Env from "./Env";
let serverURL = Env.serverURL;

function getHeaders(upload) {
  let user = getUser();
  if (upload) {
    return {
      "x-access-token": user.token
    };
  }

  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  if (user) {
    headers["x-access-token"] = user.token;
  }
  return headers;
}

export default {
  get: function(url) {
    url = serverURL + url;
    return fetch(url, { method: "GET", headers: getHeaders() })
      .then(processResponse)
      .catch(processError);
  },

  post: function(url, data) {
    url = serverURL + url;
    return fetch(url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(data)
    })
      .then(processResponse)
      .catch(processError);
  },

  patch: function(url, data) {
    url = serverURL + url;
    return fetch(url, {
      method: "PATCH",
      headers: getHeaders(),
      body: JSON.stringify(data)
    })
      .then(processResponse)
      .catch(processError);
  },

  delete: function(url) {
    url = serverURL + url;
    return fetch(url, { method: "DELETE", headers: getHeaders() })
      .then(processResponse)
      .catch(processError);
  },

  put: function(url, data) {
    url = serverURL + url;
    return fetch(url, {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify(data)
    })
      .then(processResponse)
      .catch(processError);
  },

  uploadFiles: function(url, files) {
    url = serverURL + url;
    return fetch(url, {
      method: "POST",
      headers: getHeaders(true),
      body: files
    })
      .then(processResponse)
      .catch(processError);
  },

  downloadFile: function(url) {
    url = serverURL + url;
    return fetch(url, {
      method: "GET",
      headers: getHeaders(true)
    })
      .then(response => response.blob())
      .then(blob => {
        // var url = window.URL.createObjectURL(blob);
        // var a = document.createElement('a');
        // a.href = url;
        // a.download = "filename.xlsx";
        // document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        // a.click();
        // a.remove();  //afterwards we remove the element again
        return blob;
      });
  }
};

async function processResponse(response) {
  let json = await response.json();
  if (!json || !json.success) {
    throw new Error(
      json ? json.message : "Server Error. Please try again later."
    );
  }
  return json;
}

function processError(err) {
  // window.store.dispatch(toastMessage('Server Error. Please try again later', 'error', 10));
  console.log(err.message);
  throw err;
  // return {};
}
