import React from "react";
import { RowMenu, RowMenuItem } from "../widgets/RowMenu";
import { clearStored, getUser } from "../../services/DataService";
import Icon from "../icons/Icon";
import { disconnectSocket } from "../../services/Socket";
import { useNavigate } from "react-router-dom";

function UserMenu() {
  const navigate = useNavigate();

  const logOut = () => {
    disconnectSocket();
    navigate("/signin");
    clearStored();
  };

  const openUserProfile = () => {
    navigate("/profile");
  };

  const user = getUser() || {};
  return (
    <RowMenu
      menuButton={
        <a>
          <strong className="font-bold font-lg">{user.name}</strong>
        </a>
      }
      whiteMenu={true}
      transition
    >
      <React.Fragment>
        <RowMenuItem onClick={openUserProfile}>
          <div className="link d-flex align-items-center">
            <Icon name="person" style={{ fontSize: "20px" }} />
            <span className="ml-1"> Profile </span>
          </div>
        </RowMenuItem>
        <RowMenuItem onClick={logOut}>
          <span
            className="link d-flex align-items-center"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Icon name="logout" style={{ fontSize: "18px" }} />
            <span className="ml-1">Logout</span>
          </span>
        </RowMenuItem>
      </React.Fragment>
    </RowMenu>
  );
}

export default UserMenu;
