import AnswerEditor from "./AnswerEditor";
import RichTextView from "../docs/Editor/RichTextView";
import AnswersContainer from "./AnswersContainer";
import { useState } from "react";
import { doAddAnswer } from "../../../state/actions/question";
import { connect } from "react-redux";

function QuestionContent({ question, groupId, addAnswer }) {
  const [showAdd, setShowAdd] = useState(false);

  const add = (content) => {
    addAnswer(groupId, question._id, content);
    setShowAdd(false);
  };

  return (
    <div>
      <div className="my-3">
        <RichTextView content={question.content} />
      </div>

      <hr style={{ margin: "0 0 1rem 0" }} />
      <div style={{ paddingLeft: 30 }}>
        {showAdd ? (
          <div className="white-box mb-3">
            <AnswerEditor submit={add} cancel={() => setShowAdd(false)} />
          </div>
        ) : (
          <div className="my-2">
            <a className="link" onClick={() => setShowAdd(true)}>
              Add Your Answer
            </a>
          </div>
        )}
        <AnswersContainer question={question} groupId={groupId} />
      </div>
    </div>
  );
}

const actions = {
  addAnswer: doAddAnswer,
};

export default connect(null, actions)(QuestionContent);
