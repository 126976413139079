import React, { useState, forwardRef, useImperativeHandle } from "react";
import TextEditor from "../docs/Editor/TextEditor";

const TaskCommentForm = forwardRef(function (props, ref) {
  const [comment, setComment] = useState(props.comment || "");

  useImperativeHandle(ref, function getDescription() {
    return comment;
  });

  return (
    <TextEditor
      setContent={(content) => setComment(content)}
      content={comment}
      className="minimal"
      placeholder="Type your comment here."
      minimal
    />
  );
});

export default TaskCommentForm;
