import React, { Component } from "react";
import { connect } from "react-redux";
import { getUser } from "../../services/DataService";
import { Outlet, redirect } from "react-router-dom";
import TopNav from "./TopNav";
import { setUpDataReceiver, setUpSocket } from "../../services/Socket";
import SocketProvider from "../../sockets/SocketProvider";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let user = getUser();
    if (user) {
      this.setState({ user });
     // setUpSocket();
      //this.props.setUpDataReceiver();
    } else {
      redirect("/signin");
    }
  }

  render() {
    let { user } = this.state;
    if (!user) {
      return null;
    }
    return (
        <SocketProvider>
        <div>
          <div style={{ margin: 0, height: "100vh", backgroundColor: "#fff" }}>
            <TopNav />
            <div className="wrapper wrapper-content d-block">
              <Outlet />
            </div>
          </div>
        </div>
        </SocketProvider>
    );
  }
}

const actions = {
  setUpDataReceiver,
};

export default connect(null, actions)(Main);
