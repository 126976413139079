import { useCurrentEditor } from "@tiptap/react";
import Icon from "../../../icons/Icon";
import React from "react";

const MenuBar = ({ editor }) => {
  // const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  return (
    <div className="control-group">
      <div className="button-group d-flex flex-wrap">
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={
            editor.isActive("bold") ? "tip-button tip-is-active" : "tip-button"
          }
        >
          <Icon fontSize={18} name="format_bold" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={
            editor.isActive("italic")
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          <Icon fontSize={18} name="format_italic" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={
            editor.isActive("strike")
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          <Icon fontSize={18} name="strikethrough_s" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={!editor.can().chain().focus().toggleCode().run()}
          className={
            editor.isActive("code") ? "tip-button tip-is-active" : "tip-button"
          }
        >
          <Icon fontSize={18} name="code" />
        </button>
        <button
          onClick={() => {
            editor.chain().focus().unsetAllMarks().run();
            editor.chain().focus().clearNodes().run();
          }}
          className="tip-button"
        >
          <Icon fontSize={18} name="format_clear" />
        </button>
        {/*<button onClick={() => editor.chain().focus().clearNodes().run()}>*/}
        {/*  Clear nodes*/}
        {/*</button>*/}
        <button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={
            editor.isActive("paragraph")
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          <Icon fontSize={18} name="segment" />
        </button>

        {/*<select*/}
        {/*  className="tip-select"*/}
        {/*  onChange={(event) => {*/}
        {/*    editor*/}
        {/*      .chain()*/}
        {/*      .focus()*/}
        {/*      .toggleHeading({ level: parseInt(event.target.value) })*/}
        {/*      .run();*/}
        {/*  }}*/}
        {/*  value={editor.getAttributes("heading").level}*/}
        {/*>*/}
        {/*  <option value={""}>H</option>*/}
        {/*  <option value="1">H1</option>*/}
        {/*  <option value="2">H2</option>*/}
        {/*  <option value="3">H3</option>*/}
        {/*  <option value="4">H4</option>*/}
        {/*  <option value="5">H5</option>*/}
        {/*  <option value="6">H6</option>*/}
        {/*</select>*/}

        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          className={
            editor.isActive("heading", { level: 1 })
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          H1
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={
            editor.isActive("heading", { level: 2 })
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          H2
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          className={
            editor.isActive("heading", { level: 3 })
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          H3
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
          className={
            editor.isActive("heading", { level: 4 })
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          H4
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 5 }).run()
          }
          className={
            editor.isActive("heading", { level: 5 })
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          H5
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 6 }).run()
          }
          className={
            editor.isActive("heading", { level: 6 })
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          H6
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={
            editor.isActive("bulletList")
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          <Icon fontSize={18} name="list" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={
            editor.isActive("orderedList")
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          <Icon fontSize={18} name="format_list_numbered" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={
            editor.isActive("codeBlock")
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          <Icon fontSize={18} name="code" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={
            editor.isActive("blockquote")
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          <Icon fontSize={18} name="format_quote" />
        </button>
        <button
          className="tip-button"
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
        >
          <Icon fontSize={18} name="horizontal_rule" />
        </button>
        <button
          className="tip-button"
          onClick={() => editor.chain().focus().setHardBreak().run()}
        >
          Hard break
        </button>
        <button
          className="tip-button"
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <Icon fontSize={18} name="undo" />
        </button>
        <button
          className="tip-button"
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <Icon fontSize={18} name="redo" />
        </button>
        <button
          onClick={() => editor.chain().focus().setColor("#958DF1").run()}
          className={
            editor.isActive("textStyle", { color: "#958DF1" })
              ? "tip-is-active tip-button"
              : "tip-button"
          }
        >
          Purple
        </button>
      </div>
    </div>
  );
};

export default MenuBar;
