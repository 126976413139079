import React, { Component } from "react";
import FileView from "./FileView";
import FileViewInline from "./FileViewInline";
import { deleteFile } from "../../../services/FileService";

export default class FilesGrid extends Component {
  handleDeleteFile = (groupId, fileId) => {
    deleteFile(groupId, fileId).then(() => {
      const updatedFiles = this.props.files.filter((file) => file._id !== fileId);
      this.props.updateFiles(updatedFiles);
    });
  };

  render() {
    let { files } = this.props;
    if (!files || files.length === 0) {
      return <div>There are no files.</div>;
    }

    const uniqueFiles = Array.from(new Set(files.map(file => file._id)))
        .map(id => files.find(file => file._id === id));

    return uniqueFiles.map((file) => {
      if (this.props.inline) {
        return (
          <div className="col-md-4" key={file._id}>
            <FileViewInline
              file={file}
              openFile={this.props.openFile}
              deleteFile={this.handleDeleteFile}
              highlight={
                this.props.fileIdToHighlight &&
                this.props.fileIdToHighlight === file._id
              }
            />
          </div>
        );
      }
      return (
        <FileView key={file._id} file={file} openFile={this.props.openFile} />
      );
    });
  }
}