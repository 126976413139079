import Icon from "../icons/Icon";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { getGroups } from "../../services/GroupService";
import ModalPopup from "../modal/ModalPopup";
import FeedEditor from "../feeds/FeedEditor";
import GroupSideBar from "./GroupSideBar";

const SideBar = () => {
  const [groups, setGroups] = useState([]);
  const [showCreate, setShowCreate] = useState(false);

  useEffect(() => {
    loadGroups();
  }, []);

  const loadGroups = async () => {
    const groups = await getGroups("REGULAR");
    setGroups(groups);
  };

  const toggleShowCreate = () => {
    setShowCreate(!showCreate);
  };

  const { groupId } = useParams();

  return (
    <>
      <ul className="nav d-flex flex-column">
        <li>
          <NavLink
            to="/home"
            className={({ isActive, isPending }) =>
              isActive ? "link sb-nav-active" : ""
            }
            style={{ display: "flex", alignItems: "center" }}
          >
            <Icon name="view_timeline" fontSize={22}></Icon>
            <span className="ml-2">Feed</span>
          </NavLink>
        </li>

        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "link sb-nav-active" : "")}
            to="/home/groups"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Icon style={{ fontSize: 22 }} name="workspaces" />
            <span className="ml-2">Groups</span>
          </NavLink>
          <ul className="nav nav-second-level d-flex flex-column">
            {groups.map((group) => (
              <li style={{ paddingLeft: "20px" }} key={group._id}>
                <NavLink
                  to={`/home/groups/${group._id}`}
                  style={{ display: "flex", alignItems: "center", padding: 7 }}
                >
                  <span> {group.name}</span>
                </NavLink>
                {groupId && group._id === groupId && <GroupSideBar />}
              </li>
            ))}
          </ul>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "link sb-nav-active" : "")}
            to="/home/chat"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Icon name="chat" fontSize={22}></Icon>
            <span className="ml-2">Chat</span>
          </NavLink>
        </li>

        <li>
          <div style={{ padding: "10px" }}>
            <button
              className="btn btn-dark dfac btn-sm"
              style={{ width: "90%" }}
              onClick={toggleShowCreate}
            >
              <Icon name="add" fontSize={22}></Icon>
              <span className="ml-1">Create</span>
            </button>
          </div>
        </li>
      </ul>

      {showCreate && (
        <ModalPopup handleClose={toggleShowCreate} fullscreen>
          <div className="row justify-content-center align-items-center">
            <div className="col-sm-12 col-md-6 col-md-offset-3">
              <h3>New Content</h3>
              <FeedEditor onCreateFeed={toggleShowCreate} />
            </div>
          </div>
        </ModalPopup>
      )}
    </>
  );
};

export default SideBar;
