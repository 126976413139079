import io from "socket.io-client";
import { getUser } from "./DataService";
import Env from "./Env";
import {
  ADD_CHAT_MESSAGE,
  ADD_CONTENT,
  DO_NOTHING,
  EDIT_CHAT_MESSAGE,
  EDIT_CONTENT,
  REMOVE_CHAT_MESSAGE,
  REMOVE_CONTENT,
} from "../state/actionTypes";
import store from "../state/store";
let socket;

function setUpSocket() {
  socket = io(Env.serverURL, {
    transports: ["websocket"],
    reconnection: true,
    auth: {
      token: getUser().token,
    },
  });


  socket.io.on("reconnect", (attempt) => {
    let group = store.getState().group;
    if (group && group.groupData && group.groupData._id) {
      socket.emit("group:open", group.groupData._id);
    }
    console.log("reconnected");
  });
}

function disconnectSocket() {
  if (socket) {
  }
  socket.disconnect();
}

function registerMultipleGroups(groupIds) {
  groupIds.forEach((groupId) => openGroup(groupId));
}

function openGroup(groupId) {
  if (socket) {
    socket.emit("group:open", groupId);
  }
}

function closeGroup(groupId) {
  if (socket) {
    socket.emit("group:close", groupId);
  }
}

function contentUpdatesHandler(dispatch, getState, message) {
  let user = getUser();
  let { userId, groupId, contentType } = message;

  let { currentPage } = getState().ui;
  let ignore = user && user._id === userId;
  if (ignore) {
    return doIgnore(dispatch);
  }

  if (currentPage === "GROUPS") {
    // let { messagesLoaded } = getState().chat;
    if (
      contentType !== "chat"
      // || !messagesLoaded[groupId]
    ) {
      ignore = true;
    }
  } else {
    let { groupData, contentLoaded } = getState().group;
    if (
      groupData &&
      groupData._id !== groupId
      // || !contentLoaded[contentType]
    ) {
      ignore = true;
    }
  }

  if (ignore) {
    return doIgnore(dispatch);
  }

  if (contentType === "chat") {
    processChatMessage(dispatch, message);
  } else {
    processNonChatContent(dispatch, message);
  }
}

function doIgnore(dispatch) {
  dispatch({
    type: DO_NOTHING,
  });
}

function processChatMessage(dispatch, message) {
  let { action, groupId, contentId, data } = message;

  if (action === "insert") {
    dispatch({
      type: ADD_CHAT_MESSAGE,
      payload: {
        groupId,
        message: data,
      },
    });
  } else if (action === "update") {
    dispatch({
      type: EDIT_CHAT_MESSAGE,
      payload: {
        groupId,
        messageId: contentId,
        content: data,
      },
    });
  } else if (action === "delete") {
    dispatch({
      type: REMOVE_CHAT_MESSAGE,
      payload: { groupId, itemId: contentId },
    });
  }
}

function processNonChatContent(dispatch, message) {
  let { action, contentId, data, contentType } = message;

  if (action === "insert") {
    dispatch({
      type: ADD_CONTENT,
      payload: {
        item: data,
        type: contentType,
      },
    });
  } else if (action === "update") {
    dispatch({
      type: EDIT_CONTENT,
      payload: {
        itemId: contentId,
        type: contentType,
        content: data,
      },
    });
  } else if (action === "delete") {
    dispatch({
      type: REMOVE_CONTENT,
      payload: { itemId: contentId, type: contentType },
    });
  }
}

function setUpDataReceiver() {
  return (dispatch, getState) => {
    socket.on("content-updates", (message) => {
      contentUpdatesHandler(dispatch, getState, message);
    });
  };
}

function requestMessages() {
  return {
    type: "REQUEST_MESSAGES",
  };
}

function receiveMessages(messages) {
  return {
    type: "RECEIVE_MESSAGES",
    data: { messages: messages },
    isFetching: false,
  };
}

function receiveMessage(message) {
  return {
    type: "RECEIVE_MESSAGE",
    data: message,
  };
}

function addMessage(message) {
  return {
    type: "ADD_MESSAGE",
    data: { message: message },
  };
}

function emitUserInfo(userInfoId) {
  return () => {
    socket.emit("set-user-info", userInfoId);
  };
}

function emitToken(token) {
  return () => {
    socket.emit("set-user-token", token);
  };
}

function emitBsToken(token) {
  return () => {
    socket.emit("set-bs-token", token);
  };
}

function setupAllMessagesReceiver() {
  return (dispatch, getState) => {
    dispatch(requestMessages());
    socket.on("all-messages-user", function (messages) {
      messages = messages || [];
      dispatch(receiveMessages(messages));
    });
  };
}

function processMessage(message) {
  return (dispatch) => {
    dispatch(addMessage(message));
    socket.emit("user-message", message);
  };
}

function setupNoUserHandler(cb) {
  return (dispatch) => {
    socket.on("no-user", function () {
      cb();
      dispatch({ type: "DUMMY" });
    });
  };
}

export {
  setUpSocket,
  registerMultipleGroups,
  openGroup,
  closeGroup,
  setUpDataReceiver,
  disconnectSocket,
  setupAllMessagesReceiver,
  processMessage,
  emitUserInfo,
  emitToken,
  emitBsToken,
  setupNoUserHandler,
};
