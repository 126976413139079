import Icon from "../icons/Icon";
import React from "react";

export function getIcon(type, fontSize) {
  let style = { fontSize };

  if (type === "task") {
    return <Icon style={style} name="done_all" />;
  }
  if (type === "text") {
    return <Icon style={style} name="list_alt" />;
  }
  if (type === "bookmark") {
    return <Icon style={style} name="bookmarks" />;
  }
  if (type === "doc") {
    return <Icon style={style} name="notes" />;
  }
  if (type === "kanban") {
    return <Icon style={style} name="view_column" />;
  }
  if (type === "files") {
    return <Icon style={style} name="folder" />;
  }
  if (type === "sub-collections") {
    return <Icon style={style} name="workspaces" />;
  }
  if (type === "media-post") {
    return <Icon style={style} name="newspaper" />;
  }
  if (type === "drawing") {
    return <Icon style={style} name="draw" />;
  }
  if (type === "code") {
    return <Icon style={style} name="code" />;
  }
  if (type === "question") {
    return <Icon style={style} name="question_answer" />;
  }
  if (type === "chat") {
    return <Icon style={style} name="chat" />;
  }
}
