import React from "react";

class EventForm extends React.PureComponent {
  constructor(props) {
    super(props);
    let event = props.event || {};
    this.state = { ...event };
  }

  onChangeTitle = e => {
    this.setState({ title: e.target.value });
  };

  onChangeDescription = e => {
    this.setState({ description: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.submit({ ...this.state });
  };

  render() {
    let { title, description } = this.state;
    return (
      <form onSubmit={this.onSubmit} className="form-horizontal">
        <div className="ibox">
          <div className="ibox-content">
            <div className="form-group mt-3">
              <label className="mb-2 d-flex align-items-center">Title</label>
              <input
                className="form-control"
                value={title}
                onChange={this.onChangeTitle}
                style={{ borderRadius: 4 }}
              />
            </div>

            <div className="form-group mt-3">
              <label className="mb-2 d-flex align-items-center">
                Description
              </label>
              <textarea
                className="form-control"
                value={description}
                onChange={this.onChangeDescription}
                rows={4}
                style={{ borderRadius: 4 }}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-end">
            <button type="submit" className="btn btn-primary ">
              {this.props.create ? "Create Event" : "Update"}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default EventForm;
