import Icon from "../../../icons/Icon";
import React from "react";

const MinimalMenuBar = ({ editor }) => {
  // const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  return (
    <div className="control-group">
      <div className="button-group d-flex flex-wrap">
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={
            editor.isActive("bold") ? "tip-button tip-is-active" : "tip-button"
          }
        >
          <Icon fontSize={18} name="format_bold" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={
            editor.isActive("italic")
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          <Icon fontSize={18} name="format_italic" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={
            editor.isActive("strike")
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          <Icon fontSize={18} name="strikethrough_s" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={!editor.can().chain().focus().toggleCode().run()}
          className={
            editor.isActive("code") ? "tip-button tip-is-active" : "tip-button"
          }
        >
          <Icon fontSize={18} name="code" />
        </button>
        <button
          onClick={() => {
            editor.chain().focus().unsetAllMarks().run();
            editor.chain().focus().clearNodes().run();
          }}
          className="tip-button"
        >
          <Icon fontSize={18} name="format_clear" />
        </button>

        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          className={
            editor.isActive("heading", { level: 1 })
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          H1
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={
            editor.isActive("heading", { level: 2 })
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          H2
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          className={
            editor.isActive("heading", { level: 3 })
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          H3
        </button>

        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={
            editor.isActive("bulletList")
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          <Icon fontSize={18} name="list" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={
            editor.isActive("orderedList")
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          <Icon fontSize={18} name="format_list_numbered" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={
            editor.isActive("codeBlock")
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          <Icon fontSize={18} name="code" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={
            editor.isActive("blockquote")
              ? "tip-button tip-is-active"
              : "tip-button"
          }
        >
          <Icon fontSize={18} name="format_quote" />
        </button>

        <button
          className="tip-button"
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <Icon fontSize={18} name="undo" />
        </button>
        <button
          className="tip-button"
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <Icon fontSize={18} name="redo" />
        </button>
      </div>
    </div>
  );
};

export default MinimalMenuBar;
