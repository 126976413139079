import React, {Component} from "react";
import FilesUploader from "./FilesUploader";
import FilesGrid from "./FilesGrid";
import FileViewer from "./FileViewer";
import {BarLoader} from "react-spinners";

export default class ExpandedFilesView extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  toggleExpand = () => {
    this.setState({ groupExpanded: !this.state.groupExpanded });
  };

  openFile = (file) => {
    this.setState({ loading: true });
    this.props
      .openFile(file._id)
      .then((fileURL) => {
        this.setState({ file, fileURL, loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  };

  render() {
    let { files, groupId } = this.props;
    let { file, fileURL, loading } = this.state;

    return (
      <>
        <div className={`ibox float-e-margins`}>
          <div className="ibox-title content-title group-header">
            {/*<GroupName name={group.name} groupId={groupId} type={"files"} />*/}
            <h3>Files</h3>
          </div>
          <div
            className="ibox-content"
            style={{
              height: "78vh",
              overflow: "auto",
            }}
          >
            <div className="row no-gutters ">
              <div className="col-sm-12 col-md-4 ">
                <div className="file-manager">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <FilesUploader
                      groupId={groupId}
                      onFilesUpload={this.props.loadFiles}
                      inline
                    />
                  </div>
                </div>

                <div
                  className="feed-activity-list px-2"
                  style={{ borderRight: "1px solid rgba(0,0,0,.125)" }}
                >
                  <FilesGrid
                    files={files}
                    openFile={this.openFile}
                    fileIdToHighlight={file ? file._id : null}
                    inline
                  />
                </div>
                <div className="clearfix" />
              </div>

              <div className="col-sm-12 col-md-8">
                <BarLoader
                  color={"#2d85c6"}
                  loading={loading}
                  css={"display: block; margin: 0 auto; border-color: red;"}
                  size={150}
                />

                {fileURL && (
                  <FileViewer file={file} fileURL={fileURL} embedded />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
